import React                        from 'react';
import ReactDOM                     from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { setModalForm }             from "../../../storage/global";

const MyModalForm = ({fullScreen, isOpen, title, form, withoutClose = null}) => {

    const modalForm = useSelector(state => state.global.modalForm)
    const dispatch = useDispatch();

    const myForm = {
        show: isOpen ?? modalForm.show,
        withoutClose: withoutClose != null ? withoutClose : modalForm.withoutClose,
        title: title ?? modalForm.title,
        form: form ?? modalForm.form,
        fullScreen: fullScreen ?? modalForm.fullScreen,
        hideOut: modalForm.hideOut,
        styles: modalForm.styles,
        formContent: modalForm.formContent,
        formBody: modalForm.formBody,
        hideCloseButton: !!(modalForm.hideCloseButton ?? false),
        hideAction: modalForm.hideAction,
    }


    if (!myForm.show) return null;

    function hide(e) {
        myForm.hideAction && myForm.hideAction();
        dispatch(setModalForm({
            ...myForm,
            show: false,
            formContent: null,
            hideAction: null
        }))
    }

    function hideOut(e) {
        if (e.target.id === 'modal-form' && myForm.hideOut)
            hide(e)
    }

    return ReactDOM.createPortal(
        <div
            id={'modal-form'}
            className={`modal-form d-flex justify-content-center align-items-center ${myForm.fullScreen ? 'modal-form-fullscreen' : ''}`}
            onClick={hideOut}
            style={{...myForm.styles}}
        >
            <div className="modal-form-center">
                <div
                    id={'modal-form-content'}
                    className={`modal-form-content ${myForm.formContent ? myForm.formContent.classNames : ''}`}
                >
                    <div className={'position-relative'}>
                        {
                            myForm.title ?
                                <div className={'modal-form-header'}>

                                    <div className={'d-flex justify-content-between align-items-center'}>
                                        <span
                                            style={{
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {myForm.title}
                                        </span>
                                    </div>

                                </div>
                                : null
                        }
                        {!myForm.withoutClose && <img
                            onClick={hide}
                            style={{
                                position: 'absolute',
                                top: 18,
                                right: 20,
                                backgroundColor: 'white',
                                border: '1px solid #8492a8',
                                borderRadius: '100%',
                                zIndex: 10
                            }}
                            className={'my-modal-close cursor-pointer'} src="/assets/img/close.svg"
                            alt="close"/>}
                    </div>
                    <div className={`modal-form-body ${myForm.title && myForm.title.length && 'modal-form-body-with-title'} ${myForm.formBody ? myForm.formBody.classNames : ''}`}>
                        {myForm.form}
                    </div>
                </div>
            </div>
        </div>
        , document.body);
};

export default MyModalForm;

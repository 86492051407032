import React, { useState, useEffect }                          from 'react';
import { useDispatch, useSelector }                 from "react-redux";
import { useNavigate }                              from "react-router-dom";
import { Button, Form }                             from "react-bootstrap";
import { useTranslation }                           from "react-i18next";

import InputPassword                                from "../input/InputPassword";
import { DEFAULT_ERROR_OBJECT,
    EMAIL,
    PHONE,
    PHONE_VERIFY,
    PASSWORD,
    PASSWORD_CONFIRM,
    PASSWORD_AGAIN,
    routesNames
}   from "../../constants";
import {
    noEmptyValidate,
    validateLength,
    phoneValidate,
    isPhone,
    matchValidate,
    passwordValidateAlphabet,
    passwordValidateLenght
}   from "../../utils/validate";
import { checkValidate, serializeFormToObject, viewAlert }      from "../../utils/misc";
import { restorePass }                                          from "../../utils/api";
import { setAlert, setLoadSpinner, clearRestorePasswordData }   from "../../storage/global";

const NewPasswordForm = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { connection_id, code } = useSelector(state => state.global.restorePasswordData)

    const [pass, setPass] = useState({
        first: '',
        second: '',
    })

    const [errorPassword, setErrorPassword] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPasswordAgain, setErrorPasswordAgain] = useState({...DEFAULT_ERROR_OBJECT});

    const [errorRestorePassword, setRestorePassword] = useState({...DEFAULT_ERROR_OBJECT});

    useEffect(() => {
        return () => {
            dispatch(clearRestorePasswordData());
        }
    }, [])

    const validations = {}
    validations[PASSWORD] = [noEmptyValidate(t('validEmpty')), passwordValidateAlphabet(t('validPasswordAlphabet')), passwordValidateLenght(t('validPassword'))]
    validations[PASSWORD_CONFIRM] = [noEmptyValidate(t('validEmpty')), passwordValidateAlphabet(t('validPasswordAlphabet')), passwordValidateLenght(t('validPassword'))]

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        const errors = [
            checkValidate(pass.first, validations[PASSWORD], setErrorPassword),
            checkValidate(pass.second, validations[PASSWORD_CONFIRM].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain),
        ]
        const isError = errors.filter(er => er.isError).length

        const data = {
            connection_id,
            code,
            password: pass.first
        }
        if (!isError) {
            dispatch(setLoadSpinner(true))
            restorePass(data)
                .then(response => {
                    viewAlert(dispatch, response, {
                        text: t('passwordSuccessfullyReset'),
                        type: 'success',
                    })
                    setTimeout(() => {
                        navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                        viewAlert(dispatch, response, {show: false})
                    }, 3000)
                })
                .catch(({response}) => {
                    dispatch(setAlert({
                        text: response.statusText,
                        type: 'danger',
                    }))
                })
                .finally(() => {
                    dispatch(setLoadSpinner(false))
                    dispatch(clearRestorePasswordData());
                })
        }
    }

    function handleValues(value, name) {
        switch (name) {
            case PASSWORD:
                setPass({...pass, first: value})
                checkValidate(value, validations[name], setErrorPassword)
                break;
            case PASSWORD_CONFIRM:
                setPass({...pass, second: value})
                checkValidate(value, validations[name].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain)
                break;
            default:
                break;
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <InputPassword
                id={PASSWORD}
                type={'password'}
                placeholder={t(PASSWORD)}
                classes={'mt-4'}
                errorMessage={errorPassword.message}
                required={true}
                value={pass.first}
                handleChange={handleValues}
                closeButton
                validations={validations[PASSWORD]}
                handleBlur={() => {}}
            />
            <InputPassword
                id={PASSWORD_CONFIRM}
                type={'password'}
                placeholder={t(PASSWORD_AGAIN)}
                classes={'mt-4'}
                errorMessage={errorPasswordAgain.message}
                required={true}
                value={pass.second}
                handleChange={handleValues}
                closeButton
                validations={validations[PASSWORD_CONFIRM]}
                handleBlur={() => {}}
            />

            <Button type="submit" className={'w-100 mt-4'}>
                {t('restore')}
            </Button>

            <Button onClick={event => navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)} variant="outline-primary"
                    className={'w-100 mt-3'}>
                {t('cancel')}
            </Button>

        </Form>
    );
};

export default NewPasswordForm;

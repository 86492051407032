import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";

import {
    setModalForm,
} from "../../../storage/global";
import ProfileForm from "../../forms/ProfileForm";

const WeChatLoginModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    if (!isOpen) return null;

    const title = t('WeChatTitle');
    const message = `${t('WeChatSubtitle1')}\n${t('WeChatSubtitle2')}`;
    const buttonText = t('Add_Login');

    const handleAddLogin = () => {
        dispatch(setModalForm({
            title: t('profile'),
            form: <ProfileForm/>,
            hideOut: true,
            hideCloseButton: true,
            formContent: {classNames: 'modal-form-content-profile'},
        }))
        onClose && onClose();
    };

    return ReactDOM.createPortal(
        <div className="modal-form d-flex justify-content-center align-items-center">
            <div className="modal-form-center minW-M-D">
                <div className="modal-form-content" style={{
                    position: 'relative',
                    maxWidth: '620px',
                    borderRadius: '0',
                    overflow: 'hidden'
                }}>
                    <img
                        src="/assets/img/UnpaidParcelBg.png"
                        alt="background"
                        style={{
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 0
                        }}
                    />

                    <button
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            top: '18px',
                            right: '20px',
                            backgroundColor: 'white',
                            border: '1px solid #8492a8',
                            borderRadius: '100%',
                            zIndex: 10,
                            padding: 0,
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <span style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#8492a8'
                        }}>×</span>
                    </button>

                    <div style={{
                        position: 'relative',
                        zIndex: 1,
                        padding: '40px 20px',
                        textAlign: 'center'
                    }}>
                        <img
                            src="/assets/img/WeChat-Login.png"
                            alt="WeChat"
                            style={{
                                width: '180px',
                                height: 'auto',
                                margin: '0 auto 24px'
                            }}
                        />

                        <h2 style={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#1A2B48',
                            marginBottom: '16px'
                        }}>
                            {title}
                        </h2>

                        <p style={{
                            color: '#0A2432',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontFamily: 'Montserrat',
                            whiteSpace: 'pre-line'
                        }}>
                            {message}
                        </p>

                        <button
                            onClick={handleAddLogin}
                            style={{
                                backgroundColor: '#E60033',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                color: '#FEFEFE',
                                border: 'none',
                                borderRadius: '40px',
                                padding: '12px 60px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.2s',
                                marginTop: '24px'
                            }}
                            onMouseOver={e => e.target.style.backgroundColor = '#C41530'}
                            onMouseOut={e => e.target.style.backgroundColor = '#E60033'}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default WeChatLoginModal;

import React, { useEffect, useState }          from 'react';
import { useDispatch }                                  from "react-redux";
import { Button }                                       from "react-bootstrap";
import moment                                           from "moment/moment";

import { setLoadSpinner }                      from "../../storage/global";
import { makePostRequest}  from "../../utils/api";
import { viewAlert }                                    from "../../utils/misc";
import MyDatePicker                                     from "../../components/shared/datepicker/MyDatePicker";

const TabABHsCommod = () => {

    const dispatch = useDispatch();

    const [dateForAB, setForAB] = useState({ date_from: Date.now(), date_to: Date.now()});
    const [averageCreationTimeStandart, setAverageCreationTimeStandart] = useState('');
    const [averageCreationTimeHsOrCommod, setAverageCreationTimeHsOrCommod] = useState('');

    useEffect(() => {
    }, [])

    function loadData() {
        dispatch(setLoadSpinner(true))
        makePostRequest('/api/admin/getAverageCreationTimeABHsCommod', {
                date_from: dateForAB.date_from,
                date_to: dateForAB.date_to,
            },
            {}
        ).then(({data}) => {
            const timeInMenutesClassic = data.averageCreationTimeClassic/60;
            const timeInMenutesSelect = data.averageCreationTimeSelect/60;
            setAverageCreationTimeStandart(Math.round(timeInMenutesClassic * 100) / 100);
            setAverageCreationTimeHsOrCommod(Math.round(timeInMenutesSelect * 100) / 100);
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function loadExcel() {
        dispatch(setLoadSpinner(true))
        makePostRequest('/api/admin/printABHsCommod', {
                date_from: dateForAB.date_from,
                date_to: dateForAB.date_to
            },
            {responseType: 'blob'}
        ).then(({data}) => {
            if(!data) {
                return viewAlert(dispatch, {"err": 'Something went wrong'});
            }
            const date = moment();
            const filename = `ab_analytics_hs_commod_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDatePicker(dateObject, setDate) {
        const date = {
            date_from: dateObject.firstDate.format('YYYY.MM.DD'),
            date_to: dateObject.secondDate.format('YYYY.MM.DD')
        }
        setDate(date)
    }

    return (
        <div className={'container-page max-height-sub-tabs mt-3'}>
            <div className="px-3 pt-10 mb-3">
                Статистика форми створення посилок, тести з вибором HS коду та вибором опису
            </div>
            <div className="row w-100 m-0">
                <MyDatePicker
                    handle={(dateObj) => handleDatePicker(dateObj, setForAB)}
                    startEnd
                    firstDate={new Date(dateForAB.date_from ?? Date.now()-1000*60*60)}
                    secondDate={new Date(dateForAB.date_to ?? Date.now())}
                    className={'d-flex col-6'}
                    stylesFirstDate={{marginRight: '15px'}}
                    classNameFirstDate={'mr-3'}
                    textFirst={"З дати"}
                    textSecond={"До дати"}
                    colFirst={['auto', 'auto']}
                    colSecond={['auto', 'auto']}
                    TitleCClassNames={"pl-15 "}
                    TitleClassNames={"lh-0"}
                    DPCClassNames={"mtb-9 minw-100"}
                />
                <div className="col-6 align-self-center">
                    <Button onClick={loadExcel} className={'hmc mr-1 w-100'}>Скачати excel по механізму створення</Button>
                </div>
            </div>
            <div className="row mt-4 px-3">
                <div className="col-6 align-self-center">
                    <Button onClick={loadData} className={'hmc mr-1 w-100'}>Показати середній час створення посилок</Button>
                </div>
             </div>
            <div className="row mt-2 mx-2 px-4">
                Cередній час створення звичайною формою: {averageCreationTimeStandart} хв.
                <br/>
                Cередній час створення формою з каталогом HS кодів та описом: {averageCreationTimeHsOrCommod} хв.
             </div>
        </div>
    );
};


export default TabABHsCommod;

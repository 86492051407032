import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import {
    CAPTCHA_SWITCH,
    DEFAULT_ERROR_OBJECT,
    EMAIL,
    INPUT_CAPTCHA,
    MANAGER,
    BIRTHDAY,
    COMPANY_NAME,
    NAME,
    PASSWORD, PASSWORD_AGAIN,
    PASSWORD_CONFIRM,
    PHONE,
    routesNames,
    PHONE_VERIFY,
    api_misc_kk_analytics,
} from "../../constants";
import {
    emailValidate,
    emailSpecialCharactersValidate,
    noEmptyValidate, matchValidate,
    isCorrectOption,
    numberValidate,
    letterSpaceHyphenValidate3,
    letterNumberSpaceHyphenValidate,
    passwordValidateAlphabet,
    passwordValidateLenght,
    validateLength,
} from "../../utils/validate";
import { getCookie }        from "../../utils/cookie";
import {checkValidate, serializeFormToObject, viewAlert, debounce} from "../../utils/misc";
import {getManagerList, signup, checkPhoneNumExistKK, checkEmailExistKK, makeGetRequest, makePostRequest } from "../../utils/api";
import InputText from "../input/InputText";
import InputName from "../input/InputName";
import InputPhone from "../input/InputPhone";
import InputEmail from "../input/InputEmail";
import InputPassword from "../input/InputPassword";
import Select from "../input/Select";
import Switch from "../input/Switch";
import {setLoadSpinner} from "../../storage/global";
import PhoneVerify from "./PhoneVerify";

const ford_id = 'registration-form';

const RegisterForm = () => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentLocation = useLocation();

    const defManager = {GUID: '', FullName: t('selectManager'), email: ""};
    const formatDate = 'yyyy.MM.dd';

    const [errorCompany, setErrorCompany] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorName, setErrorName] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPhone, setErrorPhone] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassword, setErrorPassword] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPasswordAgain, setErrorPasswordAgain] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCaptcha, setErrorCaptcha] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorManager, setErrorManager] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorBirthday, setErrorBirthday] = useState({...DEFAULT_ERROR_OBJECT});

    const [pass, setPass] = useState({
        first: '',
        second: '',
    })

    const [company_name, setCompanyName] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [emailDuplicate, setEmailDuplicate] = useState('')
    const [manager, setManager] = useState(defManager)
    const [managerList, setManagerList] = useState([defManager])
    const [birthday, setBirthday] = useState('')
    const [loadManagerList, setLoadManagerList] = useState(true)
    const [robot, setRobot] = useState(false)
    const [trySubmit, setTrySubmit] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneIsCheckNumExistKK, setPhoneIsCheckNumExistKK] = useState(false)
    const [emailIsCheckExistKK, setEmailIsCheckExistKK] = useState(false)
    const [phoneIsVerify, setPhoneIsVerify] = useState(false)
    const [emailUnique, setEmailUnique] = useState(false)
    const [connectionId, setConnectionId] = useState('')
    const [verifyCode, setVerifyCode] = useState('')
    const [sendAnaliticsStartInput, setSendAnaliticsStartInput] = useState(false)
    const [forbiddenCompanyName, setForbiddenCompanyName] = useState([])

    const [withABTest, setWithABTest] = useState(false);
    const [abIdReg, setAbIdReg] = useState(0);

    const [refWeChatInfo, setRefWeChatInfo] = useState({});
    const [refWeChatInfoMobile, setRefWeChatInfoMobile] = useState({});

    const [btnWeChatStatus, setBtnWeChatStatus] = useState('start');
    const [closeFormSource, setCloseFormSource] = useState('');

    const formRef = useRef(null);
    const [formData, setFormData] = useState({});
    const formDataRef = useRef(formData);
    formDataRef.current = formData
    const robotRef = useRef(robot);
    robotRef.current = robot
    const phoneIsVerifyRef = useRef(phoneIsVerify);
    phoneIsVerifyRef.current = phoneIsVerify
    const withABTestRef = useRef(withABTest);
    withABTestRef.current = withABTest
    const abIdRegRef = useRef(abIdReg);
    abIdRegRef.current = abIdReg
    const formSourceRef = useRef(closeFormSource);
    formSourceRef.current = closeFormSource;

    const checkPhoneExistKK = useMemo(() => {
        const [debouncedFunc] = debounce(checkPhoneNumExistKK, 700);
        return debouncedFunc;
    }, [])
    
    const [debounceCheck] = useMemo(() => {
        const [checkDebounce] = debounce(({fn, args}) => fn(...args), 2000);
        return [checkDebounce];
    }, [])

    const birthdayMin = useMemo(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const birthdayMin_ = new Date(yyyy - 15, today.getMonth(), today.getDate())
        return birthdayMin_;
    }, [])

    const code_inviting_client = useMemo(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const cic = queryParams.get('code_inviting_client');
        return cic;
    }, [currentLocation]);

    const wechat_uuid = useMemo(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const wechat_uuid = queryParams.get('wechat_uuid');
        const reg_user_data_for_wechat =  sessionStorage.getItem('reg_user_data_for_wechat')
        if(wechat_uuid && reg_user_data_for_wechat) {
            const regUserDataForWechat = JSON.parse(reg_user_data_for_wechat);
            setCompanyName(regUserDataForWechat[COMPANY_NAME]);
            setName(regUserDataForWechat[NAME]);
            setEmail(regUserDataForWechat[EMAIL]);
            setPhone(regUserDataForWechat[PHONE]);
            setPhoneIsVerify(regUserDataForWechat.phoneIsVerify);
            setConnectionId(regUserDataForWechat.connectionId);
            setVerifyCode(regUserDataForWechat.verifyCode);
            setPass({
                first: regUserDataForWechat[PASSWORD],
                second: regUserDataForWechat[PASSWORD],
            });
            sessionStorage.removeItem("reg_user_data_for_wechat");
        }
        return wechat_uuid;
    }, [currentLocation]);

    useEffect(() => {
        if(code_inviting_client) {
            const params = {
                UserId: code_inviting_client,
            };
            makePostRequest("/api/misc/RecordReferalLinkClick ", params).then((res) => {
                // console.log("🚀 RecordReferalLinkClick res:", res);
            })
            .catch((err) => {
                console.log('RecordReferalLinkClick catch: ', err);
            })
        }
    }, [code_inviting_client]);

    const checkEmailExist = useMemo(() => {
        const [debouncedFunc] = debounce(checkEmailExistKK, 700);
        return debouncedFunc;
    }, [])

    const validations = {}
    validations[COMPANY_NAME] = [noEmptyValidate(t('validEmpty')),
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterNumberSpaceHyphenValidate(t('IncorrectYourName'))
    ]
    validations[NAME] = [noEmptyValidate(t('validEmpty')),
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterSpaceHyphenValidate3(t('IncorrectYourName'))
    ]
    validations[PHONE] = [noEmptyValidate(t('validEmpty')), numberValidate(t('validNumber')), validateLength(5, 0, t('IncorrectPhoneNumber'))]
    validations[EMAIL] = [noEmptyValidate(t('validEmpty')), emailValidate(t('validEmail')), emailSpecialCharactersValidate(t('validEmail'))]
    validations[PASSWORD] = [
        ...(wechat_uuid ? [] : [noEmptyValidate(t('validEmpty'))]),
        passwordValidateAlphabet(t('validPasswordAlphabet')),
        passwordValidateLenght(t('validPassword'))
    ]
    validations[PASSWORD_CONFIRM] = [
        ...(wechat_uuid ? [] : [noEmptyValidate(t('validEmpty'))]),
        passwordValidateAlphabet(t('validPasswordAlphabet')),
        passwordValidateLenght(t('validPassword'))
    ]
    validations[INPUT_CAPTCHA] = [noEmptyValidate(t('validEmpty'))]
    validations[MANAGER] = [noEmptyValidate(t('validEmpty')), isCorrectOption(t('validEmpty'), 'GUID')]
    validations['MANAGER_GUID'] = [noEmptyValidate(t('validEmpty'))]
    validations['BIRTHDAY'] = []

    useEffect(() => {
        getManagerList()
            .then((response) => {
                setManagerList([defManager].concat(response.data))
                setLoadManagerList(false)
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })

        makeGetRequest('/api/misc/getForbiddenCommodity', {
                checking_type: "company_name"
            }
        ).then(({data}) => {
            data && setForbiddenCompanyName(data.response || []);
        })
        .catch(({response}) => {
            console.log("🚀 getForbiddenCommodity catch:", response);
            viewAlert(dispatch, response)
        })

        let withABTest_ = false;
        makeGetRequest('/api/misc/getABTests', {}).then((res) => {
            if(res.data && res.data.length) {
                const testForShow = res.data.map( t => ({
                    id: t.id,
                    name: t.name,
                    active: t.active,
                    date_from: new Date(t.date_from),
                    date_to: new Date(t.date_to),
                }));
                const testReg = testForShow.find(test => test.id === 'ab_registration_form');
                if(testReg.active && testReg.date_from <= (new Date()) && testReg.date_to >= (new Date())) {
                    setWithABTest(true)
                    withABTest_ = true;
                }
            } else {
                setWithABTest(false)
            }
        })
        .catch((err) => {
            console.log("🚀 getABTests err:", err);
            viewAlert(dispatch, err)
        })
        .finally(() => {
            const ab_id_reg = getCookie('ab_id_reg');
            setAbIdReg(ab_id_reg);
            const queryParams = new URLSearchParams(currentLocation.search);
            const wechat_uuid = queryParams.get('wechat_uuid');
            if(!wechat_uuid) {
                makeGetRequest(api_misc_kk_analytics, {
                    action: 'registration_form_open',
                    a_data: !withABTest_ ? '{}' : JSON.stringify({
                        ab_id_reg
                    })
                });
            }
        });
        window.addEventListener("beforeunload", onBeforeUnload);
        return (preFormData_) => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        }
    }, [])

    function onBeforeUnload(ev) {
        ev?.preventDefault();
        ev.returnValue = "";
        if(!formSourceRef.current) {
            saveCheckingFormData(formDataRef.current);
        } else {
            setCloseFormSource('');
            formSourceRef.current = '';
        }
        return;
    }

    function saveCheckingFormData(formData_) {
        if (formData_) {
            const data = formData_;
    
            data[PHONE] = data[PHONE]?.toString()?.replaceAll(/[ -]*/ig, '') || '';
            data.connection_id = connectionId;
            data.code = verifyCode;
            data.managerEmail = "";
            managerList.forEach(mng => {
                if(manager.GUID && mng.GUID === manager.GUID ) {
                    data.managerEmail = mng.email;
                }
            });

            const keysFields = [
                COMPANY_NAME,
                NAME,
                PHONE,
                EMAIL,
                PASSWORD,
                PASSWORD_CONFIRM,
                INPUT_CAPTCHA,
                MANAGER,
            ];
            const errors = [];
            keysFields.forEach( (key) => {
                if(data[key]) errors.push({field: key, ...checkValidate(data[key], validations[key]),});
            })
            errors.push({field: CAPTCHA_SWITCH, isError: !robotRef.current, message: !robotRef.current ? 'not set' : ''});

            const statFormData = {
                errors,
                phoneIsVerify: phoneIsVerifyRef.current,
                withABTest: withABTestRef.current,
                abIdReg: abIdRegRef.current,
            }
            makeGetRequest(api_misc_kk_analytics, {
                action: 'registration_form_close',
                a_data: JSON.stringify(statFormData)
            });
        }
    }

    function checkForbiddenCompanyName(value: string, setError): boolean {
        return !!forbiddenCompanyName
            .map(fc => {

                const reg = new RegExp(`${!fc.SearchByEntry ? '^' : ''}(${fc.Text})${!fc.SearchByEntry ? '$' : ''}`, 'ig')

                const isError = reg.test(value.trim());
                if (isError) {
                    setError({
                        isError: true,
                        message: t('validValueForbidden')
                    })
                }
                return isError;

            })
            .find(v => v)
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        setTrySubmit(true);

        const form = e.currentTarget;
        const data = serializeFormToObject(form)

        data[PHONE] = data[PHONE].toString().replaceAll(/[ -]*/ig, '')
        data.connection_id = connectionId;
        data.code = verifyCode;
        data.managerEmail = "";
        data.lng = i18n.language;
        if(birthday) {
            const d = new Date(birthday);
            const m = moment(d);
            const birthday_ = m.format();
            data.birthday = birthday_;
        } else {
            data.birthday = '';
        }
        data.wechat_uuid = wechat_uuid || '';
        managerList.forEach(mng => {
            if(manager.GUID && mng.GUID === manager.GUID ) {
                data.managerEmail = mng.email;
            }
        });

        const errors = [
            checkValidate(data[COMPANY_NAME], validations[COMPANY_NAME], setErrorCompany),
            checkValidate(data[NAME], validations[NAME], setErrorName),
            checkValidate(data[PHONE], validations[PHONE], setErrorPhone),
            checkValidate(data[EMAIL], validations[EMAIL], setErrorEmail),
            checkValidate(data[PASSWORD], validations[PASSWORD], setErrorPassword),
            checkValidate(data[PASSWORD_CONFIRM], validations[PASSWORD_CONFIRM].concat([matchValidate(data[PASSWORD], t('passwordMatch'))]), setErrorPasswordAgain),
            checkValidate(data[INPUT_CAPTCHA], validations[INPUT_CAPTCHA], setErrorCaptcha),
            checkValidate(data[MANAGER], validations['MANAGER_GUID'], setErrorManager),
            checkValidate(data[BIRTHDAY], validations['BIRTHDAY'], setErrorBirthday),
        ]

        const isError = errors.filter(er => er.isError).length
        const its_dev_registration = process.env.REACT_APP_DEV_REGISTRATION;
        if (!isError
            && robot
            && (((phoneIsVerify || its_dev_registration) && !withABTest)
                || (phoneIsVerify && withABTest && abIdReg && abIdReg % 2 != 0)
                || (withABTest && abIdReg && abIdReg % 2 == 0)
            )
            && emailUnique
        ) {

            dispatch(setLoadSpinner(true))

            signup(data)
                .then(async (response) => {
                    const { useruid, ID } = response.data || {};
                    await makeGetRequest(api_misc_kk_analytics, {
                        action: 'registration_form_signup',
                        a_data: !withABTest ? '{}' : JSON.stringify({
                            ab_id_reg: abIdReg,
                            user_code: ID
                        })
                    });
                    if(code_inviting_client) {
                        const params = {
                            UserId: code_inviting_client,
                            RefUserId: ID
                        };
                        makePostRequest("/api/misc/referalPromo", params).then((res) => {
                            makeGetRequest('/api/misc/createUserQRCodes', {usercode: ID}).catch((err) => {
                                console.log("🚀 createUserQRCodes err:", err);
                            })
                        })
                        .catch((err) => {
                            console.log('referalPromo catch: ', err);
                        })
                        .finally(() => {
                            viewAlert(dispatch, response)
                            setTimeout(() => {
                                navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                                viewAlert(dispatch, response, {show: false})
                            }, 2000)
                        });
                    } else {
                        viewAlert(dispatch, response)
                        setTimeout(() => {
                            navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                            viewAlert(dispatch, response, {show: false})
                        }, 2000)
                    }
                })
                .catch(({response}) => {
                    console.log("🚀 signup err res:", response);
                    viewAlert(dispatch, response)
                })
                .finally(() => dispatch(setLoadSpinner(false)))
        }
    }

    function checkCompany(value, validation, setErrorCompany) {
        checkValidate(value, validation, setErrorCompany);
        checkForbiddenCompanyName(value, setErrorCompany);
    }

    function checkName(value, validation, setErrorName) {
        checkValidate(value, validation, setErrorName)
        checkForbiddenCompanyName(value, setErrorName);
    }

    function checkPhone(value, validation, setErrorPhone) {
        const validError = checkValidate(value, validation, setErrorPhone)
        setPhoneIsVerify(false)
        if(value && !validError.isError) {
            setPhoneIsCheckNumExistKK(false)
            setErrorPhone({
                isError: true,
                message: t('phone_checking')
            })
            checkPhoneExistKK({phone: value}).then((response) => {
                if(response.exist) {
                    setErrorPhone({
                        isError: true,
                        message: t('phone_already_registered')
                    })
                } else if(response.status) {
                    setErrorPhone({
                        isError: true,
                        message: 'Error, status: ' + response.status
                    })
                } else if(!response.exist) {
                    setErrorPhone({
                        isError: false,
                        message: ''
                    })
                }
                setPhoneIsCheckNumExistKK(true);
            }).catch((err) => {
                console.log("🚀 checkPhoneExistKK err:", err);
                setErrorPhone({
                    isError: true,
                    message: err.status ? 'Error, status: ' + err.status : err.toString()
                })
            })
        }
    }

    function checkEmail(value, validation, setErrorEmail) {
        const validError = checkValidate(value, validation, setErrorEmail);
        if(value && !validError.isError) {
            setEmailIsCheckExistKK(false)
            setErrorEmail({
                isError: true,
                message: t('email_checking')
            })
            checkEmailExist({email: value}).then((response) => {
                if(response.exist) {
                    setEmailUnique(false);
                    setErrorEmail({
                        isError: true,
                        message: t('email_already_registered')
                    })
                } else if(response.status) {
                    setEmailUnique(false);
                    setErrorEmail({
                        isError: true,
                        message: 'Error, status: ' + response.status
                    })
                } else if(!response.exist) {
                    setEmailUnique(true);
                    // повторюємо перевірку бо за час запиту значення можна змінити
                    checkValidate(emailDuplicate, validations[EMAIL], setErrorEmail);
                    // setErrorEmail({
                    //     isError: false,
                    //     message: ''
                    // })
                }
                setEmailIsCheckExistKK(true);
            }).catch((err) => {
                console.log("🚀  checkEmailExist  err:", err);
                setEmailUnique(false);
                setErrorEmail({
                    isError: true,
                    message: err.status ? 'Error, status: ' + err.status : err.toString()
                })
            })
        }
    }

    function checkPassword(value, validation, setErrorPassword) {
        checkValidate(value, validation, setErrorPassword);
    }

    function checkPasswordAgain(value, validation, setErrorPasswordAgain) {
        checkValidate(value, validation, setErrorPasswordAgain);
    }

    function checkManager(value, validation, setErrorManager) {
        checkValidate(value, validation, setErrorManager);
    }

    async function handleValues(value, name, inputRef, eventType) { //
        switch (name) {
            case COMPANY_NAME: {
                setCompanyName(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkCompany, args: [value, validations[name], setErrorCompany]})
                } else checkCompany(value, validations[name], setErrorCompany);
                break;
            }
            case NAME: {
                setName(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkName, args: [value, validations[name], setErrorName]})
                } else checkName(value, validations[name], setErrorName);
                break;
            }
            case PHONE: {
                setPhone(value)
                if(eventType === "change") {
                    debounceCheck({fn: checkPhone, args: [value, validations[name], setErrorPhone]})
                } else checkPhone(value, validations[name], setErrorPhone)
                break;
            }
            case EMAIL: {
                setEmail(value);
                setEmailDuplicate(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkEmail, args: [value, validations[name], setErrorEmail]})
                } else checkEmail(value, validations[name], setErrorEmail)
                break;
            }
            case PASSWORD:
                setPass({...pass, first: value})
                if(eventType === "change") {
                    debounceCheck({fn: checkPassword, args: [value, validations[name], setErrorPassword]})
                } else checkPassword(value, validations[name], setErrorPassword)
                break;
            case PASSWORD_CONFIRM:
                setPass({...pass, second: value})
                if(eventType === "change") {
                    debounceCheck({fn: checkPasswordAgain, args: [value, validations[name].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain]})
                } else checkPasswordAgain(value, validations[name].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain)
                break;
            case INPUT_CAPTCHA:
                checkValidate(value, validations[name], setErrorCaptcha)
                break;
            case MANAGER: {
                setManager(value);
                if(eventType === "change") {
                    debounceCheck({fn: checkManager, args: [value, validations[name], setErrorManager]})
                } else checkManager(value, validations[name], setErrorManager)
                break;
            }
            case BIRTHDAY: {
                setBirthday(value);
                checkValidate(value, validations[name], setErrorBirthday)
                break;
            }
            case CAPTCHA_SWITCH:
                setRobot(value);
                break;
            default:
                break;
        }
        if(name !== EMAIL && name !== PASSWORD && !sendAnaliticsStartInput) {
            makeGetRequest(api_misc_kk_analytics, {
                action: 'registration_form_started_filling_up',
                a_data: !withABTest ? '{}' : JSON.stringify({
                    ab_id_reg: abIdReg
                })
            });
            setSendAnaliticsStartInput(true);
        }
        const form = formRef.current;
        const data = serializeFormToObject(form)
        setFormData(data);
    }

    function onCodeCheck(isVerify, connectionId, verifyCode) {
        setPhoneIsVerify(isVerify);
        setConnectionId(connectionId);
        setVerifyCode(verifyCode);
    }

    async function openModalWeChatQuestion() {
        const errPassConf = checkValidate(pass.second, validations[PASSWORD_CONFIRM].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain);
        if(!!pass.first && !!pass.second && errPassConf.isError) {
            console.log("🚀 PASSWORD_CONFIRM isError");
            return;
        }
        const appId = process.env.REACT_APP_WECHAT_APP_ID;
        if(!appId) return console.log('empty REACT_APP_WECHAT_APP_ID');
        const params = {};
        setBtnWeChatStatus('loading');
        const url = await makePostRequest('/api/auth/wechat/pending', params, {}).then(async (res) => {
            const uuid = res.data?.uuid;

            const redirectUri = encodeURIComponent(`${document.location.protocol}//${document.location.host}/wechat/registerCallback`);
            const state = uuid;

            const weChatUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_login&state=${state}#wechat_redirect`;
            return weChatUrl;
        })
        .catch((err) => {
            console.log('wechat/pending catch: ', err)
        })
        if(!url) return console.log('empty weChatUrl');

        setCloseFormSource('wechatBtn');
        const form = formRef.current;
        const data = serializeFormToObject(form);
        const saveData = {
            [COMPANY_NAME]: data[COMPANY_NAME],
            [NAME]: data[NAME],
            [PHONE]: data[PHONE],
            phoneIsVerify: phoneIsVerify,
            connectionId: connectionId,
            verifyCode: verifyCode,
            [EMAIL]: data[EMAIL],
            [PASSWORD]: data[PASSWORD],
        };
        const reg_user_data_for_wechat =  sessionStorage.getItem('reg_user_data_for_wechat');
        if(reg_user_data_for_wechat) sessionStorage.removeItem("reg_user_data_for_wechat");
        sessionStorage.setItem('reg_user_data_for_wechat', JSON.stringify(saveData));
        setBtnWeChatStatus('start');

        window.location.href = url;
    }

    function handleHoverWeChatInfo(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    const verification_code = withABTest && abIdReg && abIdReg % 2 == 0 ? [] : [{
        el: PhoneVerify,
        id: PHONE_VERIFY,
        classes: 'mt-4',
        placeholder: t('verification_code'),
        errorMessage: errorPhone.isError,
        handleChange: onCodeCheck,
        validations: [],
        closeButton: true,
        required: true,
        value: phone,
    }];

    const inputList = [
        {
            el: InputText,
            id: COMPANY_NAME,
            classes: '',
            placeholder: t('your_company'),
            errorMessage: errorCompany.message,
            handleChange: handleValues,
            validations: validations[COMPANY_NAME],
            closeButton: true,
            value: company_name,
        },
        {
            el: InputName,
            id: NAME,
            classes: 'mt-4',
            placeholder: t('your_name'),
            errorMessage: errorName.message,
            handleChange: handleValues,
            validations: validations[NAME],
            closeButton: true,
            value: name,
        },
        {
            el: InputPhone,
            id: PHONE,
            classes: 'mt-4',
            placeholder: t('phone_number'),
            errorMessage: errorPhone.message,
            handleChange: handleValues,
            validations: validations[PHONE],
            closeButton: true,
            value: phone,
        },
        ...verification_code,
        {
            el: InputEmail,
            id: EMAIL,
            classes: 'mt-4',
            placeholder: t(EMAIL),
            errorMessage: errorEmail.message,
            handleChange: handleValues,
            validations: validations[EMAIL],
            closeButton: true,
            value: email,
        },
        {
            el: InputPassword,
            id: PASSWORD,
            classes: 'mt-4',
            placeholder: t(PASSWORD),
            errorMessage: errorPassword.message,
            handleChange: handleValues,
            validations: validations[PASSWORD],
            closeButton: true,
            value: pass.first,
        },
        {
            el: InputPassword,
            id: PASSWORD_CONFIRM,
            classes: 'mt-4',
            placeholder: t(PASSWORD_AGAIN),
            errorMessage: errorPasswordAgain.message,
            handleChange: handleValues,
            validations: validations[PASSWORD_CONFIRM],
            closeButton: true,
            value: pass.second,
        },
    ]

    return (
        <Form onSubmit={handleSubmit} id={ford_id} ref={formRef}>
            {
                inputList.map((item, key) => {
                    return (
                        <item.el
                            key={key}
                            id={item.id}
                            classes={item.classes}
                            placeholder={item.placeholder}
                            errorMessage={item.errorMessage}
                            handleChange={item.handleChange}
                            handleBlur={item.handleChange}
                            validations={item.validations}
                            closeButton={item.closeButton}
                            value={item.value}
                            customMask={item.mask}
                            required={item.required}
                            isCheckNumExistKK={item.isCheckNumExistKK}
                        />
                    )
                })
            }
            <div className={`d-block wfc mx-auto mt-3 mb-1`}>
                {t('or').toUpperCase()}
            </div>
            <div className={`row mx-0`}>
                <Button variant=""
                    className={'col-11 my-p-0 col-bg-wechat'}
                    onClick={openModalWeChatQuestion}
                    disabled={(!!pass.first && !!pass.second && !!errorPasswordAgain.message) || btnWeChatStatus === 'loading' || !!wechat_uuid}
                >
                    <div className="mx-auto">
                        <img width='26px' height='22px' className={'wechat-white-m'} style={{}} src={"/assets/img/wechat-white.png"} alt="logo"/>
                        {btnWeChatStatus === 'start' ? t('Login_with_WeChat') : t('Loading')}
                    </div>
                </Button>
                {wechat_uuid ?
                    <div className="d-inline-flex col-1 my-p-0 align-items-center justify-content-end">
                        <img width='20px' height='20px' className={``} src={'/assets/img/check_green.png'} alt="question"/>
                    </div>
                    : <>
                        <div ref={ref => setRefWeChatInfo(ref)}
                            className={`d-none d-md-inline-flex col-1 my-p-0 cursor-pointer align-items-center justify-content-end`}
                            data-for="my-tooltip-auth-bottom"
                            data-tip={t('RegisteringWeChatInfo')}
                            data-html="true"
                            data-class={`my-tooltip`}
                            data-tooltip-place='bottom'
                            onMouseOver={event => handleHoverWeChatInfo(event, refWeChatInfo)}
                            onMouseOut={event => handleHoverWeChatInfo(event, refWeChatInfo, false)}
                        >
                            <img width='20px' height='20px' className={``} src={'/assets/img/question_blue.svg'} alt="question"/>
                        </div>
                        <div ref={ref => setRefWeChatInfoMobile(ref)}
                            className={`d-inline-flex d-md-none col-1 my-p-0 cursor-pointer align-items-center justify-content-end`}
                            data-for="my-tooltip-auth-right"
                            data-tip={t('RegisteringWeChatInfo')}
                            data-html="true"
                            data-class={`my-tooltip`}
                            data-tooltip-place='right'
                            onMouseOver={event => handleHoverWeChatInfo(event, refWeChatInfoMobile)}
                            onMouseOut={event => handleHoverWeChatInfo(event, refWeChatInfoMobile, false)}
                        >
                            <img width='20px' height='20px' className={``} src={'/assets/img/question_blue.svg'} alt="question"/>
                        </div>
                    </>
                }
            </div>
            <Select
                id={'manager'}
                className={'mt-4 text-center'}
                options={managerList}
                selectValue={manager}
                errorMessage={errorManager.message}
                loaded={loadManagerList}
                keyName={'GUID'}
                nameValue={'FullName'}
                handleChange={handleValues}
            />
            <div className={`mt-4`}>
                <DatePicker
                    id={BIRTHDAY}
                    maxDate={birthdayMin}
                    selected={birthday}
                    onChange={(date: Date) => handleValues(date, BIRTHDAY)}
                    dateFormat={formatDate}
                    placeholderText={t('Date_of_birth')}
                    showYearDropdown
                    yearDropdownItemNumber={20}
                    // showMonthYearDropdown
                />
            </div>
            <Form.Group controlId={'recaptcha'} className={'mb-3 mt-4'}>
                <div className={'d-flex justify-content-center align-items-center mb-4'}>
                    <Switch
                        id={CAPTCHA_SWITCH}
                        handle={handleValues}
                        isInvalid={trySubmit && !robot}
                        defaultValue={robot}
                        labelText={'I am not a robot'}
                    />
                </div>
            </Form.Group>

            <Button type="submit" className={'w-100'}>
                {t('register')}
            </Button>

            <Button onClick={() => navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)}
                    variant="outline-primary" type="submit" className={'w-100 mt-3'}>
                {t('cancel')}
            </Button>
            <div className="my-3 text-center col-figma-text-primary lh-20px"
                 style={{
                    borderRadius: '20px',
                    padding: '8px 18px',
                    border: '1px solid',
                    // borderColor: '#0061AF',
                    // color: '#0061AF',
                    // color: '#0d6efd',
                    borderColor: '#dc3545',
                    // borderColor: '#000',
                 }}
            >
                <strong>
                    {t('register_message')}
                </strong>
            </div>
        </Form>
    );
};

export default RegisterForm;

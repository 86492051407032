/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo, useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LogRocket from 'logrocket';
import { trackEvent, AMPLITUDE_EVENTS } from '../utils/amplitudeTracker';

import {
    setAlert,
    setBalance,
    setBalanceFree,
    setBalanceNotPaid,
    setInsuranceCountres,
    setlostParsels,
    setRules,
    setIsMobile,
    setLoad,
    setModalForm,
    setProfile,
    setShowMenu,
    RECEIVERS,
    SET_NEWS_PARAMS,
    SET_NEWS,
    SET_NEWS_STOP_CAROUSEL,
    SET_KEY,
    setCountryList,
    defHeadNotificationBarSettings,
} from "../storage/global";

import {
    COMPANY_NAME,
    CONTACT_NAME,
    EMAIL,
    PHONE,
    routesNames,
    api_misc_kk_analytics,
} from "../constants";

import FakeDoors from "../components/header/FakeDoors";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Menu from "../components/menu/Menu";
import PollForm from "../components/forms/PollForm";
import {
    getCountryList as requestCountries,
    getBalance,
    getInsuranceCountry,
    getLastActiveQuestionTemplate,
    getNotificationSettings,
    getNotPaidParcels,
    getProfileSettings,
    getUserInfo,
    getLostParsels,
    loadStatuses,
    makePostRequest,
    makeGetRequest,
} from "../utils/api";
import {checkValidate, viewAlert }                from "../utils/misc";
import { getCookie }       from "../utils/cookie";
import { noEmptyValidate } from "../utils/validate";
import axios from "../lib/axios";
import ProfileForm, { getValidationsSet } from "../components/forms/ProfileForm";
import Crisp from "../components/shared/modal/Crisp";
import WeChatLoginModal from "../components/shared/modal/WeChatLoginModal";

const RootApp = () => {

    const location = useLocation();
    const currentPage = location.pathname.split('/').filter(Boolean).pop() || 'dashboard';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {t} = useTranslation();

    let fakeDoorUserId = getCookie('fakeDoorUserId');

    const lang = useSelector(state => state.global.locale)
    const load = useSelector(state => state.global.load);
    const showMenu = useSelector(state => state.global.showMenu);
    const isMobile = useSelector(state => state.global.isMobile);
    const profile = useSelector(state => state.global.profile)
    const headNotificationBarSettings = useSelector(state => state.global.headNotificationBarSettings)
    const fakeDoorUserIdRedux = useSelector(state => state.global.fakeDoorUserId)
    const showFakeDoorRedux = useSelector(state => state.global.showFakeDoor)
    const countryList = useSelector(state => state.global.countryList);

    const [intervalId, setIntervalId] = useState('');
    const [footerIsOpen, setFooterIsOpen] = useState(true);
    const [emailLinkChecking, setEmailLinkChecking] = useState(false);
    const [wechatAlertIsOpen, setWechatAlertIsOpen] = useState(false);

    const handleBackToWebsite = () => {
        trackEvent(AMPLITUDE_EVENTS.BACK_TO_WEBSITE_CLICK, {
            pageSection: 'header'
        });
    };

    const handleInstructionClick = () => {
        trackEvent(AMPLITUDE_EVENTS.INSTRUCTION_CLICK, {
            pageSection: 'header'
        });
    };

    const fakeDoorsIsOpen = useMemo(() => {
        const oiOpen = !!headNotificationBarSettings?.isActive && fakeDoorUserIdRedux && showFakeDoorRedux;
        return oiOpen;
    }, [headNotificationBarSettings, fakeDoorUserIdRedux, showFakeDoorRedux])


    useEffect(() => {
        clearInterval(intervalId)
        setIntervalId(setInterval(() => {
            if (isMobile !== (window.innerWidth < 1200)) dispatch(setIsMobile(window.innerWidth < 1200))
        }, 1000))
    }, [isMobile]);

    useEffect(() => {
        if (window.$crisp && profile?.usercode) {
            window.$crisp.push(["set", "user:nickname", [`ID: ${profile.usercode}`]]);
            window.$crisp.push(["set", "session:data", [
                [["customer_id", profile.usercode]]
            ]]);
            window.$crisp.push(["set", "session:data", [
                [["source", `cabinet/${currentPage}`]]
            ]]);
        }
    }, [profile, currentPage]);

    useEffect(() => {
        const params = new URLSearchParams(location?.search);
        const linkSource = params.get('linkSource');
        if(linkSource && !emailLinkChecking) {
            const clientID = params.get('clientID');
    
            setEmailLinkChecking(true);
            if (linkSource === 'email_registration') {
                makeGetRequest(api_misc_kk_analytics, {
                    action: 'email_open_kk_analytics',
                    a_data: JSON.stringify({
                        client_id: clientID,
                        email_type: 'registration_letter',
                        date: new Date(),
                        event_type: 'cabinet',
                    })
                });
            } else if (linkSource === 'email_promo_code') {
                makeGetRequest(api_misc_kk_analytics, {
                    action: 'email_open_kk_analytics',
                    a_data: JSON.stringify({
                        client_id: clientID,
                        email_type: 'promo_code_letter',
                        date: new Date(),
                        event_type: 'cabinet',
                    })
                });
            }
        }
    }, []);

    useEffect(() => {
        process.env.REACT_APP_LOGROCKET_INIT_KEY && LogRocket.init(process.env.REACT_APP_LOGROCKET_INIT_KEY);
        dispatch(setLoad(true))

        loadStatuses(dispatch);

        let uid = '';
        let wechatUnionId = '';
        let profileInfo = {};
        let notificationSettings = {};
        let invite_referral_alert_open = false;
        let poll_form_data = {};
        getUserInfo()
            .then(response => {
                uid = response.data.useruid;
                dispatch(setProfile(response.data));
                profileInfo = response.data;
                const logRocketProfile = {
                    company_name: profileInfo.company_name,
                    contact_name: profileInfo.contact_name,
                    currency: profileInfo.currency,
                    ic_crd_num: profileInfo.ic_crd_num,
                    name: profileInfo.name,
                    phone: profileInfo.phone,
                    sign: profileInfo.sign,
                    user_type: profileInfo.user_type,
                    usercode: profileInfo.usercode,
                    username: profileInfo.username,
                    wechat: profileInfo.wechat,
                }
                wechatUnionId = profileInfo.WechatUnionId;
                process.env.REACT_APP_LOGROCKET_INIT_KEY && profileInfo && profileInfo.usercode && LogRocket.identify(profileInfo.usercode, logRocketProfile);
                return {profileInfo};
            })
            .then(async ({profileInfo}) => {
                const data = await getNotificationSettings().then(({data}) => data);
                notificationSettings = data;
                return {profileInfo, notificationSettings: data}
            })
            .then(async ({profileInfo, notificationSettings}) => {
                await getNotPaidParcels()
                    .then(({data}) => {
                        dispatch(setBalanceNotPaid(data))
                        if (notificationSettings?.debtNotice) {
                            if (Number(data.debt) > 0) {
                                dispatch(setAlert({
                                    text: t('noPaidNotification')
                                        .replace(/(\$number)/, data.count)
                                        .replace(/(\$amount)/, data.debt)
                                        .replace(/(\$currency)/, profileInfo.currency),
                                    type: 'danger'
                                }))
                            }
                        }
                    })
                    .catch(() => {
                    })
                    .finally()
                return {notificationSettings}
            })
            .then(() => {
                getBalance()
                    .then(response => {
                        dispatch(setBalance(response.data?.balance?.item?.bal ?? ''))
                        dispatch(setBalanceFree(response.data?.balance?.item?.bal_free ?? ''))
                    })
                    .catch(({response}) => {
                        viewAlert(dispatch, response)
                    })
            })
            .then(() => {
                getInsuranceCountry()
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(setInsuranceCountres(res.data));
                        }
                    })
                    .catch(({response}) => {
                        console.log('getInsuranceCountry catch: ', response)
                    })
            })
            .then(() => {
                getLostParsels({id: uid})
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(setlostParsels(res.data));
                        }
                        if (res.data.length && notificationSettings?.parcelsNotice) {
                            const trList = res.data.map( (el) => el.TrackNumber).join(', ')
                            dispatch(setAlert({
                                text: t('lostParcelsNotification')
                                    .replace(/(\$trackNumbers)/, trList),
                                type: 'danger'
                            }))
                        }
                    })
                    .catch(({response}) => {
                        console.log('getLostParsels catch: ', response)
                    })
            })
            .then(async () => {
                await getLastActiveQuestionTemplate()
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            poll_form_data = res.data;
                            dispatch({type: SET_KEY, payload: {
                                    key: 'poll',
                                    value: poll_form_data,
                                }})
                        }
                    })
                    .catch(({response}) => {
                        console.log('getLastActiveQuestionTemplate catch: ', response)
                    })
            })
            .then(() => {
                getProfileSettings()
                    .then((res) => {
                        const {fieldsChecked, check_profile, mandatory_filling} = res.data || {fieldsChecked: '', check_profile: '', mandatory_filling: ''};

                        const validations = getValidationsSet(t);

                        const {
                            companyName,
                            email,
                            idCardNumber,
                            phoneNumber,
                            wechatId,
                            yourName,
                        } = fieldsChecked || {}

                        if (companyName) {
                            validations[COMPANY_NAME].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (email) {
                            validations[EMAIL].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (idCardNumber) {
                            // validations[IC_CRD_NUM].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (phoneNumber) {
                            validations[PHONE].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (wechatId) {
                            // validations[WECHAT_ID].unshift(noEmptyValidate(t('IncorrectWeChatId')));
                        }
                        if (yourName) {
                            validations[CONTACT_NAME].unshift(noEmptyValidate(t('validEmpty')));
                        }

                        const errors = [
                            {empty: !profileInfo.company_name, ...checkValidate(profileInfo.company_name || '', validations[COMPANY_NAME])},
                            {empty: !profileInfo.contact_name, ...checkValidate(profileInfo.contact_name || '', validations[CONTACT_NAME])},
                            {empty: !profileInfo.phone, ...checkValidate(profileInfo.phone || '', validations[PHONE])},
                            {empty: !profileInfo.username, ...checkValidate(profileInfo.username || '', validations[EMAIL])},
                        ];

                        const withErrorField = errors.find(value => value.isError || value.empty);

                        if (check_profile && withErrorField) {
                            dispatch(setModalForm({
                                title: t('profile'),
                                form: <ProfileForm/>,
                                hideOut: !mandatory_filling,
                                hideCloseButton: !!mandatory_filling,
                                formContent: {classNames: 'modal-form-content-profile'},
                            }))
                        }
                    })
                    .catch()
            })
            .then(async () => {
                await makeGetRequest('/api/misc/checkReadNotifReferralProgram', {}, {})
                    .then(({data, status}) => {
                        if (status === 200 && data && !data.markAsRead) {
                            invite_referral_alert_open = true;
                            dispatch(setAlert({
                                text: <div className={``} style={{lineheight: '28px'}}>
                                    <a
                                        className={`cursor-pointer`}
                                        style={{ textDecoration: 'underline' }}
                                        href={lang === 'cn' ?
                                            "https://meestcn.cn/yaoqingyoulizuidi100yuanduoyaoduodeshangbufengding/"
                                            : "https://meestcn.cn/en/%e3%80%90referral-program%e3%80%91-invite-1-new-user-to-earn-%ef%bf%a5100-promo-code/"}
                                        target={'_blank'}
                                        rel="noreferrer"
                                    >
                                        {t('Referral_Program')}
                                    </a>
                                    {t('Invite_1_new_user')}
                                </div>,
                                type: 'warning',
                                alertIcon: <img
                                    width="24px"
                                    className={'me-2'}
                                    src="/assets/img/icon-promo-blue.svg"
                                    alt="promo"
                                />,
                                hideAction: () => {
                                    poll_form_data?.id && openPollForm(poll_form_data);
                                    makeGetRequest('/api/misc/markAsReadNotifReferralProgram', {}, {});
                                }
                            }))
                        }
                    })
                    .catch(({response}) => {
                        console.log('checkReadNotifReferralProgram catch: ', response)
                    })
            })
            .then(() => {
                const wechat_popup_show_count = localStorage.getItem('wechat_popup_show_count');
                const wechat_popup_show_last_time = localStorage.getItem('wechat_popup_show_last_time');
                const older_7_day = !wechat_popup_show_last_time || (Date.now() > wechat_popup_show_last_time + 1000*60*60*24*7);
                if(!wechatUnionId && wechat_popup_show_count <= 5 && older_7_day) {
                    setWechatAlertIsOpen(true);
                    localStorage.setItem('wechat_popup_show_count', 1);
                    localStorage.setItem('wechat_popup_show_last_time', Date.now());
                } else if(!invite_referral_alert_open && poll_form_data?.id) {
                    openPollForm(poll_form_data);
                }
            })
            .then(() => {
                makePostRequest('/api/misc/RecentRecipientsList', {
                    usercode: profileInfo.usercode,
                }, {}).then((res) => {
                    if (res.status === 200 && res.data) {
                        dispatch({type: RECEIVERS, payload: res.data});
                    }
                })
                    .catch(({response}) => {
                        console.log('RecentRecipientsList catch: ', response)
                    })
            })
            .then(() => {
                makePostRequest('/api/misc/getRulesList', {}, {}).then((res) => {
                    dispatch(setRules(res.data));
                })
                    .catch(({response}) => {
                        viewAlert(dispatch, response)
                    })
            })
            .then(() => {
                requestCountries()
                    .then(({data}) => {
                        data = data.map(item => {
                            return {
                                ...item,
                                europe: +item.isEurope,
                                priceMax: Number(item.max_cost),
                                priceMin: 0.01,
                                weightMax: Number(item.max_weight),
                                weightMin: 0.01,
                                selected: item.country_id === 'UA',
                                country_name: lang === 'cn' && item.country_name_zh.toString() ? item.country_name_zh.toString().toUpperCase() : item.country_name_en.toString().toUpperCase(),
                            };
                        })
                        dispatch(setCountryList(data))
                    })
                    .catch((response) => {
                        viewAlert(dispatch, response)
                    })
            })
            .then(() => {
                getPromoCodes();
            })
            .then(() => {
                makeGetRequest('/api/misc/getABTests', {}).then((res) => {
                    if(res.data && res.data.length) {
                        const testForShow = res.data.map( t => ({
                            id: t.id,
                            name: t.name,
                            active: t.active,
                            date_from: new Date(t.date_from),
                            date_to: new Date(t.date_to),
                        }));
                        // Залишимо лише ті тести які використовуються після залогінення
                        const testReg = testForShow.filter(test => test.id !== 'ab_registration_form');
                        dispatch({type: SET_KEY, payload: {
                            key: 'ab_tests',
                            value: testReg,
                        }})
                        if(testForShow.find(test => test.id === 'ab_create_form_by_HS_or_commodity')) {
                            makePostRequest('/api/misc/getCategoryDescription', {}).then((res) => {
                                if(res.data && res.data.length) {
                                    dispatch({type: SET_KEY, payload: {
                                        key: 'categories_description',
                                        value: res.data,
                                    }})
                                }
                            })
                            .catch((err) => {
                                console.log("🚀 ab_create_form_by_HS_or_commodity err:", err);
                                viewAlert(dispatch, err)
                            })
                        }
                    }
                })
                .catch((err) => {
                    console.log("🚀 getABTests err:", err);
                    viewAlert(dispatch, err)
                })
            })
            .then(() => {
                axios.get('/api/admin/get_news')
                    .then(({data}) => {
                        const news = JSON.parse(data);
                        dispatch({type: SET_NEWS, payload: news})
                    })
                    .catch((response) => {
                        viewAlert(dispatch, response)
                    })
            })
            .then(() => {
                axios.get('/api/admin/get_news_params')
                    .then(({data}) => {
                        try{
                            if(typeof data === 'string') {
                                const params = JSON.parse(data);
                                dispatch({type: SET_NEWS_PARAMS, payload: params})
                            }
                        } catch(err) {
                            console.log("🚀 get_news_params data:", data);
                            console.log("🚀 get_news_params err:", err);
                        }
                    })
                    .catch((response) => {
                        viewAlert(dispatch, response)
                    })
            })
            .catch(({response}) => {
                if (!response || response.status === 401) {
                    navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                    return
                }
                viewAlert(dispatch, response)
                dispatch(setLoad(false))
            })
            .finally(() => {
                dispatch(setLoad(false))
            })
            .then(() => {
                getHeadNotificationBarSettings();
            })

        return () => {
            dispatch(setProfile({}))
            dispatch(setBalance(''))
            clearInterval(intervalId)
        }
    }, [])

    async function openPollForm(poll_data) {
        if(!poll_data?.id) return;
        dispatch(setModalForm({
            title: '',
            form: <PollForm poll={poll_data}/>,
            fullScreen: true,
            formContent: {
                classNames: 'rounded-0'
            },
            formBody: {
                classNames: 'p-0 modal-form-body-poll'
            },
            hideOut: false,
            styles: {
                zIndex: 2,
                background: 'rgba(0, 0, 0, 0.5)',
            },
            // withoutClose: res.data.require
            withoutClose: true
        }))
        dispatch({type: SET_NEWS_STOP_CAROUSEL, payload: true})
    }
    async function getHeadNotificationBarSettings() {
        return makeGetRequest('/api/misc/getHeadNotificationBarSettings', {}, {}).then(async (res) => {
            if(res.status === 200) {
                if(res.data) {
                    const data = JSON.parse(res.data);
                    const newData = {
                        ...defHeadNotificationBarSettings,
                        ...data
                    }
                    let showFakeDoor = false;
                    if(fakeDoorUserId && newData?.isActive) {
                        await makeGetRequest('/api/misc/fakeDoorsUserState', { fakeDoorId: newData.notificationBarId, fakeDoorUserId }, {})
                            .then(({data}) => {
                                if(!data.fakeDoorUserActionList || data.fakeDoorUserActionList.length === 0) {
                                    showFakeDoor = true;
                                    makeGetRequest('/api/misc/fakeDoorsUserAction',
                                        {
                                            fakeDoorId: newData.notificationBarId,
                                            fakeDoorUserId,
                                            fakeDoorUserAction: 'fakeDoorUserUnique',
                                            fakeDoorResource: 'cab',
                                        }, {}
                                    );
                                } else {
                                    const userCheck = data.fakeDoorUserActionList.find( queryData => {
                                        return queryData?.uri_full.includes(`fakeDoorId=${newData.notificationBarId}&fakeDoorUserId=${fakeDoorUserId}&fakeDoorUserAction=click`)
                                    })
                                    if(!userCheck) {
                                        const notShowFakeDoorsList = data.fakeDoorUserActionList.filter( queryData => {
                                            return queryData?.uri_full.includes(`fakeDoorId=${newData.notificationBarId}&fakeDoorUserId=${fakeDoorUserId}&fakeDoorUserAction=notShowFakeDoors`)
                                        })
                                        if(notShowFakeDoorsList.length === 0
                                            || notShowFakeDoorsList.length === 3
                                            || notShowFakeDoorsList.length === 6
                                        ) showFakeDoor = true;
                                        else if(notShowFakeDoorsList.length < 8) {
                                            makeGetRequest('/api/misc/fakeDoorsUserAction', {
                                                fakeDoorId: newData.notificationBarId,
                                                fakeDoorUserId,
                                                fakeDoorUserAction: 'notShowFakeDoors',
                                            }, {})
                                        }
                                    }
                                }
                            })
                    } else if(!fakeDoorUserId) {
                        await makeGetRequest('/api/misc/fakeDoorsGetUserId', {}, {})
                            .then(({data}) => {
                                fakeDoorUserId = data.fakeDoorUserId;
                                makeGetRequest('/api/misc/fakeDoorsUserAction',
                                    {
                                        fakeDoorId: headNotificationBarSettings.notificationBarId,
                                        fakeDoorUserId,
                                        fakeDoorUserAction: 'fakeDoorUserUnique',
                                        fakeDoorResource: 'cab',
                                    }, {}
                                );
                            })
                    }
                    dispatch({type: SET_KEY, payload: {
                            key: 'fakeDoorUserId',
                            value: fakeDoorUserId,
                        }})
                    dispatch({type: SET_KEY, payload: {
                            key: 'showFakeDoor',
                            value: showFakeDoor,
                        }})
                    dispatch({type: SET_KEY, payload: {
                            key: 'fakeDoorsIcon',
                            value: data.fakeDoorsIcon,
                        }})
                    dispatch({type: SET_KEY, payload: {
                            key: 'fakeDoorsModalBG',
                            value: data.fakeDoorsModalBG,
                        }})
                    dispatch({type: SET_KEY, payload: {
                            key: 'headNotificationBarSettings',
                            value: newData,
                        }})
                }
            } else {
                viewAlert(dispatch, res.response)
            }
        })
            .catch(({response}) => {
                console.log('getHeadNotificationBarSettings catch: ', response)
                viewAlert(dispatch, {"err": response})
            })
    }

    async function getPromoCodes() {
        return makeGetRequest('/api/misc/getPromoCodes', {}).then((res) => {
            if(res.data && res.data.response && res.data.response.length) {
                const pc_ = res.data.response.map(code => {
                    code.list_type = 'promo-codes';
                    return code;
                });
                const promo_codes = pc_.map(code => {
                    const country = countryList.find(c => c.country_id === code.country);
                    const c_n = !country ?
                        code.country
                        : lang === 'cn' && country.country_name_zh ? country.country_name_zh : country.country_name_en;
                    return {...code, 'country_name': c_n}
                })
                const codes = [];
                promo_codes.forEach((code) => {
                    const findCode = codes.findIndex(cd => cd.promocodeNum == code.promocodeNum);
                    if(findCode != -1) {
                        const el = {
                            ...code,
                            finishDate: codes[findCode].finishDate < code.finishDate ? codes[findCode].finishDate : code.finishDate,
                            countriesNotEU: code.eu ? codes[findCode].countriesNotEU : [...codes[findCode].countriesNotEU, code.country_name],
                            countriesEU: code.eu ? [...codes[findCode].countriesEU, code.country_name] : codes[findCode].countriesEU,
                        };
                        codes.splice(findCode, 1, el)
                    } else {
                        const el = {
                            ...code,
                            countriesNotEU: code.eu ? [] : [code.country_name],
                            countriesEU: code.eu ? [code.country_name] : [],
                        };
                        codes.push(el);
                    }
                });
                dispatch({type: SET_KEY, payload: {
                        key: 'promoCodesList',
                        value: codes,
                    }})
            }
        })
            .catch((err) => {
                console.log("🚀 getPromoCodes err:", err);
                viewAlert(dispatch, err)
            })
    }

    function hideSmallMenu() {
        dispatch(setShowMenu(false))
    }

    function toggleFooterIsOpen() {
        setFooterIsOpen(!footerIsOpen)
    }

    function closeNotificationBar(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({type: SET_KEY, payload: {
                key: 'showFakeDoor',
                value: false,
            }})
        makeGetRequest('/api/misc/fakeDoorsUserAction', {
            fakeDoorId: headNotificationBarSettings.notificationBarId,
            fakeDoorUserId,
            fakeDoorUserAction: 'notShowFakeDoors',
        }, {})
    }

    return (

        <>
            {!load ? (
                <div className={'h-100 w-100'}>
                    <Menu/>
                    <Crisp
                        withOffset={true}
                        customerId={profile?.usercode}
                        source={`cabinet/${currentPage}`}
                    />
                    <main
                        className={'h-100 hasTouchAction'}
                        data-footerisopen={footerIsOpen}
                        data-notificationbar={fakeDoorsIsOpen}
                        id={"main"}
                    >
                        <FakeDoors
                            notificationBarIsOpen={fakeDoorsIsOpen}
                            closeNotificationBar={closeNotificationBar}
                        />
                        <Header
                            onBackToWebsite={handleBackToWebsite}
                            onInstructionClick={handleInstructionClick}
                            onLanguageChange={(lang) => {
                                trackEvent(AMPLITUDE_EVENTS.LANGUAGE_CHANGE, {
                                    pageSection: 'header',
                                    language: lang
                                });
                            }}
                            onLogout={() => {
                                trackEvent(AMPLITUDE_EVENTS.USER_LOGOUT, {
                                    pageSection: 'header'
                                });
                            }}
                            onNotificationClick={() => {
                                trackEvent(AMPLITUDE_EVENTS.NOTIFICATION_CLICK, {
                                    pageSection: 'header'
                                });
                            }}
                            onProfileClick={() => {
                                trackEvent(AMPLITUDE_EVENTS.PROFILE_CLICK, {
                                    pageSection: 'header'
                                });
                            }}
                        />
                        <div>
                            <Outlet/>
                        </div>
                        <Footer
                            footerIsOpen={footerIsOpen}
                            toggleFooterIsOpen={toggleFooterIsOpen}
                        />
                    </main>
                    <div
                        onClick={hideSmallMenu}
                        className={`background-small-menu ${showMenu ? 'opacity-1 z-index-unset' : ''}`}
                    />
                    <WeChatLoginModal isOpen={wechatAlertIsOpen} onClose={() => setWechatAlertIsOpen(false)}/>;
                </div>
            ) : null}
        </>

    );
};

export default memo(RootApp);

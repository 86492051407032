import React, { useState } from 'react';
import ReactTooltip        from "react-tooltip";

import ButtonSmallNoBorder          from "./ButtonSmallNoBorder";

const InfoWithIcon = ({className, text, info, dataClass, classNameBtn = '', classNameImg = ''}) => {

    const [ref, setRef] = useState({});

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }
    return (
        <div ref={ref => setRef(ref)}
            data-for="my-tooltip-bottom"
            data-tip={info}
            data-html="true"
            data-class={`my-tooltip ${dataClass}`}
            data-tooltip-place='bottom'
            onClick={null}
            className={`btn-disable ${className ? className : ''}`}
            onMouseOver={event =>  handleHover(event, ref)}
            onMouseOut={event => handleHover(event, ref, false)}
        >
            <ButtonSmallNoBorder classNames={`disablehover mobile-fs-11px-lh-15px-impot ${classNameBtn}`} pClasses={'p-1'}>
                <span className={`pe-2 cursor-pointer`}>
                    <img width='20px' height='20px' className={`${classNameImg}`} src={'/assets/img/question_blue.svg'} alt="question"/>
                </span>
                <span>{text}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default InfoWithIcon;

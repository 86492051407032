import React           from 'react';
import { useDispatch } from "react-redux";
import { useTranslation }           from "react-i18next";

import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";
import HelpVideo       from "./HelpVideo";
import Logo            from "../shared/Logo";
import BurgerMenu      from "../shared/BurgerMenu";
import { setShowMenu } from "../../storage/global";
import { goToSite }     from "../../utils/misc";

const HeaderSmall = ({
     className,
     onBackToWebsite,
     onInstructionClick,
     onLanguageChange,
     onLogout,
     onNotificationClick,
     onProfileClick,
 }) => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language || 'en';
    const dispatch = useDispatch();

    function showMenu(e) {
        trackEvent(AMPLITUDE_EVENTS.MENU_OPEN, {
            pageSection: 'header',
            deviceType: 'mobile'
        });
        dispatch(setShowMenu(true));
    }

    function onBackToWebsite(e) {
        goToSite(lang);
    }

    return (
        <div className={`d-flex ${className}`}>
            <div className={'col d-flex justify-content-start'}>
                <BurgerMenu
                    className={'cursor-pointer'}
                    handle={showMenu}
                />
            </div>
            <Logo className={'col d-flex justify-content-center align-items-center'}
                  goTo={() => {
                    onBackToWebsite();
                    goToSite(lang);
                  }}
                  styleLogo={{
                      width: '72px',
                      // marginTop: '-15px'
                  }}
            />
            <div className={'col d-flex justify-content-end'}>
                <HelpVideo
                    className={''}
                    onClick={onInstructionClick}
                />
            </div>
        </div>
    );
};

export default HeaderSmall;

import React from 'react';
import { useSelector } from "react-redux";
import Lottie from 'lottie-react';
import animationData from '../../_styles/files/default_loader_all_year.json';

const SpinnerRaccoon = () => {
    const spinnerRaccoon = useSelector(state => state.global.spinnerRaccoon);

    return (spinnerRaccoon ?
            <div style={{
                position: 'absolute',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                width: '100%',
                height: '100%',
                zIndex: 2
            }}
                 className={'d-flex justify-content-center align-items-center'}
            >
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="flex justify-center items-center overflow-hidden rounded-full spinner-container">
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            </div>
            : null
    );
};

export default SpinnerRaccoon;

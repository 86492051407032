import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

const WeChatAuthFailedModal = ({ isOpen, onClose, messageText }) => {
    const { t } = useTranslation();

    if (!isOpen) return null;

    const title = t('WeChatAuthFailedTitle');
    if(!messageText) messageText = t('WeChatAuthFailedMessage');
    const messages = messageText.split('\n');
    const buttonText = t('ok');

    return ReactDOM.createPortal(
        <div className="modal-form d-flex justify-content-center align-items-center">
            <div className="modal-form-center minW-M-D">
                <div className="modal-form-content" style={{
                    position: 'relative',
                    maxWidth: '600px',
                    borderRadius: '0',
                    overflow: 'hidden'
                }}>
                    <img
                        src="/assets/img/UnpaidParcelBg.png"
                        alt="background"
                        style={{
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 0
                        }}
                    />

                    <div style={{
                        position: 'relative',
                        zIndex: 1,
                        padding: '40px 20px',
                        textAlign: 'center'
                    }}>
                        <img
                            src="/assets/img/identification-failed.png"
                            alt="Identification Failed"
                            style={{
                                width: '180px',
                                height: 'auto',
                                margin: '0 auto 24px'
                            }}
                        />

                        <h2 style={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#1A2B48',
                            marginBottom: '16px'
                        }}>
                            {title}
                        </h2>

                        <div style={{
                            color: '#0A2432',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontFamily: 'Montserrat',
                            marginBottom: '16px'
                        }}>
                            {messages.map((message, index) => (
                                <p key={index}
                                    style={{
                                        margin: '8px 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: message }}
                                >
                                </p>
                            ))}
                        </div>

                        <button
                            onClick={onClose}
                            style={{
                                backgroundColor: '#E60033',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                color: '#FEFEFE',
                                border: 'none',
                                borderRadius: '40px',
                                padding: '12px 60px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.2s',
                                marginTop: '24px'
                            }}
                            onMouseOver={e => e.target.style.backgroundColor = '#C41530'}
                            onMouseOut={e => e.target.style.backgroundColor = '#E60033'}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default WeChatAuthFailedModal;

import React, { useState, useEffect }                                      from 'react';
import { useSelector }                                          from "react-redux";
import { useNavigate, Outlet }                                  from "react-router-dom";
import { useTranslation }                                       from "react-i18next";

import { routesNames } from "../../constants";

const AdminABTestsPanel = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    const currentPath = document.location.pathname
    const tabs = [
        {
            name: 'Settings',
            path: routesNames.AB_TEST_SETTINGS
        },
        {
            name: 'Registration lists',
            path: routesNames.AB_TEST_LIST
        },
        {
            name: 'New parcel form',
            path: routesNames.AB_TEST_HS_COMMOD
        },
    ];

    const [activeTab, setActiveTab] = useState(tabs.find(tab => currentPath.includes(tab.path)) ?? tabs[0]);

    function goTo(nameTab) {
        setActiveTab(nameTab)
        navigate(`/${nameTab.path}`)
    }

    useEffect(() => {
        if (currentPath === '/' + routesNames.AB_TEST)
            navigate('/' + routesNames.AB_TEST_SETTINGS)
    }, [])

    return (
        <div className={'container-fluid overflow-auto max-height-sub-tabs'}>
            <div className='tabs'>
                {tabs.map((nameTab: string) => (
                    <div
                        key={nameTab.name}
                        className={`tab ${activeTab.name === nameTab.name ? 'select' : ''}`}
                        onClick={_ => goTo(nameTab)}
                    >
                        {t(nameTab.name)}
                    </div>
                ))}
            </div>
            <Outlet/>
        </div>
    );
};

export default AdminABTestsPanel;

const dictionary = {
    yes: "是的",
    no: "沒有",
    or: "或者",
    sign_in: "登录",
    sign_out: "注销登录",
    register: '注册',
    registration: '注册',
    email: '邮箱地址',
    email_small: '邮箱地址',
    password: '密码',
    password_again: '输入密码',
    Upload_documents: '上传资料',
    Upload_documents_title: '如果您代表企业，请上传您的营业执照\n如果您是私人用户，请上传您的个人身份证',
    The_file_size_cannot_exceed_5_MB: '文件大小不能超过 5 MB',
    forget_password: '忘记您的密码?',
    restore_password: '复原',
    restore: '复原',
    Continue: ' 继续',
    Skip: '跳过',
    Start: '开始',
    Back: "后退",
    Close: '关闭',
    cancel: '取消',
    send: '发送',
    save: '保存',
    SaveWithoutFilter: '保存无需筛选',
    SaveAndFilter: '筛选并保存',
    Filter: '筛选',
    Choose_filtering_variants: '选择过滤变体',
    Apply_settings: '应用设置',
    Apply: '应用优惠码',
    Please_complete_the_verification: "请完成验证过程，获得对思密特物流公司服务的完整体验，并加入我们的客户忠诚度计划。",
    your_company: '公司名称',
    your_name: '您的姓名',
    name: '收件人全名',
    phone_number: '电话号码',
    phone: '电话',
    wechat: '微信',
    Login_with_WeChat: '使用微信登录',
    connect_wechat: '连接微信登录',
    update_wechat: '更新微信登录',
    wechatID: '微信号',
    LogInWithWeChat: '使用 WeChat 登入?',
    WeChatTitle: '🔹 在个人资料中添加您的 WeChat 登录信息！🔹',
    WeChatSubtitle1: '要通过 WeChat 登录，请在您的个人资料中输入 WeChat 登录信息。',
    WeChatSubtitle2: '📌 前往个人资料设置，立即添加您的登录信息！',
    RegisteringWeChatInfo: `❗ 通过微信注册带来更加便捷、安全的体验。
    <br/><b>选择这种方式</b>，您无需创建或记住密码。
    <br/>只需点击登录页面上的 '使用微信登录' 按钮, 即可轻松登录账户, 让您可以通过微信App快速便捷地访问账户。🙌`,
    Add_Login: '添加登录信息',
    ic_crd_num: '身份证号码',
    send_to_wh: '送往仓库',
    wh_text_1: '您选择了$1 个包裹一起发送给我们，请填写快递单号和件数.',
    wh: '仓库',
    cnts: '件数',
    excel_template_download: '批量上传订单表格版本',
    Download_Application_Template_Example: '下载应用程序模板示例',
    balance: '钱包',
    Debt: '债务',
    //pages
    new_parcels: '新包裹',
    waiting_arrival: '等待发货',
    sent_parcels: '已发包裹',
    unidentified_parcels: '未识别包裹',
    promo_codes: '促销代码',
    accounting: '账单',
    faq: '常见问题',
    terms_and_conditions: '思密特（Meest China）服务的条款与条件',

    admin: '管理员',
    docs: '文档',

    add_new: '添加新包裹',
    Сreate_new_parcel: '创建新包裹',
    This_is_not_my_parcel: '这不是我的包裹',
    how_is_this_section_working: '该选项如何操作',
    how_is_this_section_working_info: '在这个功能里，您会看到所有可能属于您的未识别包裹<br/>如果您确认这些包裹不属于您，请点击“不是我的”<br/>如果包裹确认是您的，您可以点击“创建订单”进行下单',
    edit: '修改',
    delete: '删除',
    print_sticker: '打印面单',
    import: '导入',
    export: '导出',
    Detailed_form: '详细表格',

    //forms
    dest_country: '目的国',
    goods_type: '货物类型',
    tooltip_goods_type: '普货-货物不含内置电池，粉末，磁性或者是液体; 敏感货-货物有内置电池（电子产品），液体，粉末，磁性（化妆品，墨盒，凝胶，甲油胶，食品添加剂，膳食补充剂，茶），液体（易燃，易爆，有毒，氧化等）; 危险品-纯电池（锂电池，氧化剂，镍铬电池，镍氢电池及成分，移动电源有或没有塑胶外壳',
    tooltip_qty: '这个是该包裹内所含各类产品的总数量，如您发了3个手电筒，2件上衣，那请填 5',
    tooltip_curp: 'CURP（Clave Única de Registro de Población）是墨西哥用于登记公民和居民的唯一身份代码，也是包裹清关所需的代码。',
    send_by: '承运商',
    qty: '数量',
    qty_placeholder: '请填货物总数量',
    weight_kg: '毛重 公斤',
    weight_kg_placeholder: '预计包裹重量',
    USD: '美金',
    CNY: 'CNY',
    value_usd: '申报价值',
    value_usd_placeholder: '美金申报货值',
    hs_code: '海关商品编码',
    hs_code_placeholder: '海关商品编码',
    tooltip_hs_code: 'HS 编码是用于清关的国际商品编码。',
    sku_code: '库存量单位编码',
    sku_code_placeholder: '库存量单位编码',
    commodity: '物品描述',
    Estimated_date_delivery_customs: '预计到达海关时间',
    not_determined: '未确定',
    commodity_placeholder: '仅限填英文',
    web_link: '购买的链接（选填）',
    last_mile_track_number: '跟踪号码(尾程)',
    web_link_placeholder: '确认货物价格的链接可以协助更快清关（可填可不填',
    ioss_number: 'IOSS增值税号',
    Insurance: '保险',
    tooltip_insurances: '保险费用',
    tooltip_ioss: '使用MEEST CHINA的IOSS税号',
    zip: '邮编号码',
    zip_placeholder: '必填',
    region: '州名',
    region_placeholder: '必填',
    city: '城市',
    city_placeholder: '必填',
    street: '收件地址',
    street_placeholder: '只填街道名楼房名跟房号',
    receiver_name: '收件人全名',
    receiver_name_placeholder: '请务必填写全名',
    receiver_phone: '收件人电话',
    receiver_phone_placeholder: '两个号码用逗号隔开',
    phone_already_registered: '该电话号码已被注册',
    phone_checking: '手机号码核查中，请耐心等待。',
    email_checking: '电子邮件核查中，请耐心等待',
    email_already_registered: '该邮箱已注册',
    receiver_email: '收件人邮箱',
    receiver_email_placeholder: '邮件地址',
    passport_series: '护照系列',
    passport_series_placeholder: 'ME',
    passport_number: '护照号',
    passport_number_placeholder: '3312487',
    tax_id_number: '税务ID号',
    tax_id_number_placeholder: '003939248',
    comments: '备注',
    comments_placeholder: '您可在这里添加您需要的备注信息',
    Fill_in_the_field: '填寫欄位',
    Annex_to_the_item: '项目附件：',

    delete_selected_parcels: '删除已选包裹',
    delete_parcels_text_1: '已选项目将被永久删除，您确定吗',

    choose_file: '选择文件',

    charge_account: '充值',
    sum: '金额',

    profile: '用户信息',

    //new parcel
    track_number: '跟踪单号',
    date_of_creating: '创建日期',
    qty_2: '件数',
    weight: '毛重',
    value: '货值',
    ship: '运输方式',
    to: '目的国',
    consignee: '收件人',
    address: '地址',
    sn_goods: '含有敏感货物',
    telephone: '电话',
    comment: '备注',
    simple_goods: '普货',
    sensitive_goods: '敏感货',
    pure_buttery: '危险品',
    general_goods: '普货',
    this_type_has_goods_type_Dangerous: '这种类型的物品有货物类型" 危险货物',

    parcel_number: '跟踪单号',
    dispatch_date: '创建时间',

    poll_name: 'Poll name',
    active_poll: 'Active poll',
    clear_cach: 'Clear cach',
    clear: 'Clear',
    survey_required: 'This survey is required',
    survey_dated: '的调查',

    list_head_created_request: 'Time and date of request',
    list_head_client_id: 'Client ID',
    list_head_req_method: 'Method',
    list_head_req_uri: 'Method uri',
    list_head_request_rate: 'Speed of request in second',
    average_request_time: 'average request time',
    warning_notification_max_value: 'warning notification max value',
    Value_has_been_changed: '货值已更改',
    Value_has_been_changed_info: '订单货值出现风险，已为您修改成系统固定货值，如需修改请联系客服。',
    Name_metrics: 'Name of metrics',

    remove_from_shipment: '移回新包裹重新编辑',

    date_sent: '接收日期',
    wh_short: '仓库',
    freight_cost: '运费 CNY',

    date_from: '开始日期',
    date_to: '结束日期',

    date: '日期',
    operation: '操作',

    prohibited_sensitive: '禁止交付敏感货物，请选择不同的交付类型。',
    prohibited_danger: '禁止交付危险品货物，请选择不同的交付类型。',
    prohibited_battery: '该类货物无法通过 {$1} 运输至 {$2}',

    alertTextAdditionalCustomDuties: ' 您输入的货值已超过 {$2} 的免税货值 {$1} USD，超过该金额的包裹将面临被收取额外海关费用的风险，您是否确认继续发运呢？',
    exceededDutyFreeAmount: '您的申报货值已超出免税金额 {$1} USD',
    IAgree: '我同意”',
    IDontAgree: '我不同意”',
    notifyTaxUA: '请注意，货值超过150欧元的部分将需要交纳税金',

    copied: '已复制',

    excludedHsCode: "您在第_行中填写的海关编码（HS Code）禁止入境目的国",
    excludedCommodity: "您所输入的商品禁止进入目的国",

    zipOnlyPUDO: '该邮政编码只有 PUDO',
    Country_ISO_not_defined: '国家 ISO 未定义',
    Not_defined_ISO: '未被任何国家/地区的 ISO 定义。（ISO 国家/地区验证后 zip 验证的软件包数量 = 0）',
    Zip_verification_request_failed: '邮政编码验证请求失败',

    errorIossMessage: '根据自2021年7月1日起欧盟实施新的清关规则，请填写您的个人IOSS税号，以便向{$1}创建包裹。IOSS 税号错误的包裹可能会被欧盟海关扣留。客户应对向本应用程序提供和填写的所有数据负责。',
    errorProhibitedCountry: '承运至乌克兰的服务暂时不可用',
    nextProblem: '来自{$1}线的包裹有下一个问题：',
    ImportResponseModalHeader: '除以下地块外，所有地块均已创建',
    ImportResponseModalHeaderError: '尚未创建地块',

    row: '行',
    rows: '多行',
    message: '信息',
    successImport: '成功导入',
    success: '成功',
    warning: '警告',
    error: '出错',

    fileNotFound: '未选择文件，请选择一个文件',
    selectFile: '选择文件',

    selectSendBy: '选择承运方式',
    selectGoodType: '选择货物类型',
    selectCountry: '选择国家',
    selectManager: '请选择您的专属经理',

    validIossNumber_1: '每个IOSS税号由12个字符组成。它应始终以“IM”开头，后跟10个不带空格的数字，例如IM0123456789',
    validLength_1: '输入值必须为{1$}个字符',
    validLength_2: '值必须为${1$}个字符',
    validLengthWords: '值必须至少包含${1$}个词',
    validLengthHsCode: '请正确输入10位数的海关商品编码',
    validPassportNumber1$: '护照序列号必须包含${1$}个数字',
    validPassportSeries1$: '护照号码必须包含两${1$}个字母',
    validName: '必须为有效名字',
    validEmpty: '该字段不可为空白',
    validCopy: '该字段必须是唯一的',
    validEmail: '邮件地址无效',
    validPassword: '您的密码必须至少包含5 个字符',
    validPasswordAlphabet: '输入无效：不支持西里尔字母。请使用拉丁字母、数字或符号。',
    validMatch: "值不匹配",
    passwordMatch: "密码不匹配",
    noMatchFound: "未找到匹配项",
    validFloat: '该值只能为数字',
    validNumber: '该值只能为数字',
    validMaxValue: '该值不得超过${1$}',
    validMaxValue2: '最大可承运重量不超过 ${1$}',
    validMinValue: '该值不得小于${1$}',
    validMinWeight: '最小可承运重量为 ${1$}- KG',
    validOnlyLetter: '值必须为字母',
    validOnlyDigitsLetter: '该值只能是数字和字母',
    validValueIncorrect: '该数值不正确',
    validValueForbidden: '該值被禁止',
    validValueChinaTrack: '国内快递单号不正确',
    validValueCurp: 'CURP 不正确，应符合以下模式，其中 A 是一个字母和一个数字：AAAA111111AAAAAAXX',
    IncorrectCompanyName: '公司名称不正确',
    IncorrectYourName: '您的姓名不正确',
    IncorrectEmail: '邮件地址无效',
    IncorrectPhoneNumber: '电话号码不正确',
    IncorrectIDCardNumber: '身份证号码不正确',
    IncorrectWeChatId: '微信号不正确',
    Incorrect: '错误',
    DomesticTrackCantMatchTrackNumber: '国内快递单号与国际单号无法匹配',

    NoOptionsAvailable: '无选项可用',

    sentResetEmail: '我们已向您发送有关如何创建新密码的说明',
    passwordSuccessfullyReset: '您的密码已经成功重置，请使用新密码重新登录。',

    notifyNewParcelForm: '根据自2021年7月1日起的欧盟清关新规，请填写您的个人IOSS税号，以便创建发向这些国家的包裹。IOSS 税号错误的包裹可能会被欧盟海关扣留。客户应对向本应用程序提供和填写的所有数据负责。',

    parcelDetails: '包裹详情',
    ConsigneeDetails: '收貨人詳細資料',

    sheet: '页',
    tape: '胶带',
    custom: '海关',
    portrait: '纵向打印',
    landscape: '横向打印',
    height: '高度',
    format: '格式',
    width: '宽度',
    orientation: '方向',
    horizontalGapTitle: '水平间距',
    horizontalGap: '水平间距',
    verticalGapTitle: '垂直间距',
    verticalGap: '垂直间距',
    bottom: '底部',
    left: '左',
    right: '右',
    top: '顶部',
    media: '标签',
    mediaType: '标签类型',
    dimensions: '尺寸',
    margins: '利润率',
    gapSize: '间隔尺寸',
    view: '预览',
    downloadPDF: '下载PDF',

    nothingSelected: '未选择任何内容',
    nothingFound: '未找到任何内容',

    search: '搜索',
    GroupSearch: '批量查询',

    pageNotFound_text_1: '哎呀呀！页面丢失啦！',
    pageNotFound_text_2: '主页！',
    Want_to_leave_page: '您想离开该页面吗？',

    updateProfile: '更新资料',

    noParcelsWaitingArrival: '没有等待到达的包裹',

    printSettings: '打印设置',
    printSettingsFor: '500 张贴纸的打印设置',
    Maximum_quantity_stickers_1: '每次打印最多可选择 500 张贴纸。',
    Maximum_quantity_stickers_2: '要继续打印，请确保您选择的贴纸数量不超过 500 张。',
    TabSystemSettings: 'System settings',
    TabPollSettings: 'Poll settings',
    TabResponsibleEmails: 'Responsible emails',
    ProfileSettings: 'Profile settings',
    NotificationSettings: 'Notification settings',
    SpeedOfRequest: 'Speed of request',
    SpeedOfRequestList: 'Speed of request list',
    SpeedOfRequestStatistic: 'Speed of request statistic',
    EmailsForWarnings: 'Emails for warnings',
    QueryByDate: 'Query by date',
    UserMetricks: 'User metricks',

    dontHaveAnyParcel: "您目前没有任何包裹",
    noSentParcels: '没有已发送包裹',
    noQuestionTemplates: 'No question templates',

    importFromExcel: '上传申请表',
    exportToExcel: '从Excel导出',
    Download_Selected_Parcels: '下载选中订单',

    removeQuestion: '是否要移除？',
    removeFromShipmentQuestion: '是否从货物中移除？',

    selectAll: '全选',
    unselectAll: '全不选',

    pay: '支付',
    requestTimeout: '发生了错误。 请稍后再试。',
    PromoCodeOnly: '可选，仅限有优惠码时使用',

    tracking: '追踪',
    TypeDelivery: '交付方式',
    toDoor: '到门服务',
    PUDO: '取件点自提服务',
    zip_only_PUDO: '该邮政编码只有 PUDO',
    noStatus: '无状态',

    //new 14/06/2022
    deniedPostalCode: '您所选择的地区无法承运',

    pay_selected: '已选择要支付的订单',
    select_pay: '请选择要支付的订单',
    unselect_pay: '未选择支付的订单',
    Discount_Amount: '折扣金额',

    balance_text: '我们优化了支付方式，从现在开始您可以为选定的包裹进行单独支付哦！',
    infoIOSS: '使用MEEST CHINA的IOSS税号',

    Notification: '通知',
    noPaidNotification: "❗系统检测到您有<span class='text-bold'>$number</span>个乌克兰订单尚未支付, 共计💲<span class='text-bold'>$amount元</span>💲, 请及时充值以便顺利出货哦, 感谢您的支持和配合",
    lostParcelNotification: '系统监测到 个未识别包裹 :  $trackNumbers 可能属于您，详情请查看“未识别包裹”',
    lostParcelsNotification: "❗系统监测到 个未识别包裹 可能属于您，详情请查看<span class='text-bold'>“未识别包裹”</span>",

    lwh: '长*宽*高（米)',
    lwhTooltip: '仓库称重',
    estimateWeight: '预计重量',
    estimateWeightTooltip: '若体积重大于实重两倍，则计费方式为:（体积重-实重）/2+实重',
    volumizingWeight: '体积重',
    volumizingWeightTooltip: '长*宽*高（米)',

    q1: '您对MEEST的下单网站操作满意度如何？下单是否方便？',
    q2: '您对MEEST时效满意度如何？',
    q3: '您对MEEST物流轨迹满意度如何？轨迹状态是否清晰明了？',
    q4: '您对MEEST处理问题件的速度是否满意？',
    q5: '您对MEEST的客服态度满意度如何？客服是否态度欠佳？',
    q6: '您对MEEST的技术支持是否满意？',
    q7: '您希望MEEST接下来开通哪些国家或者市场？',
    q8: '您是通过什么途径了解到MEEST并开始合作的？朋友介绍？网站推广？',
    q9: '您对MEEST的意见建议是？',
    Check: '查看',
    qt1: '非常满意请打5分，非常不满意请打1分',
    qt2: '非常满意请打5分，非常不满意请打1分',
    qt3: '非常满意请打5分，非常不满意请打1分',
    qt4: '非常满意请打5分，非常不满意请打1分',
    qt5: '非常满意请打5分，非常不满意请打1分',
    qt6: '非常满意请打5分，非常不满意请打1分',
    Name_process_cheking: '姓名审核中',
    Phone_process_cheking: '电话号码核查中',
    Price_process_cheking: '价格正在审核中',

    // modals
    GroupSearchMenu: '多单号查询',
    ListBarcodes: '请用空格将单号隔开',

    //tracker statuses
    returned: '回来',
    write_off_parcel: '核销包裹',

//accounting
    write_off: '注销',
    refund: '退款',
    claims: '索赔',
    other_expenses: '其他费用',
    compensation: '金钱赔偿',

    //wh
    wh_address: ' 广州市番禺区市广路钟二路段45号华家领航产业园1栋106 MEEST China 中国客户仓入仓处 / ',
    wh_copy: '复制当前仓库地址',
    contact_us: '联系我们',
    scan_QR: '扫描二维码联系我们',
    scan_QR_auth: '如有任何问题，请联系我们',
    Ok: '好的',
    Information: '信息',
    Confirm: '确认',
    ConfirmRecipientChange: '确认更改收件人信息',
    RecipientChangeQuestion: ' 您确定要更改收件人信息吗？',
    RecipientChangeUpdateInformation: '这将更新该包裹的信息。',

    // Unidentified parcels
    Domestic_express_tracking: '国内快递单号',
    Date_receiving_in_warehouse: '仓库接收日期',

    // Promo codes
    Promo_code_number: '促销代码号',
    Promo_code_amount: '促销代码金额',
    Your_referral_promo_code: '您的推荐促销代码',
    Activation_day: '启用日',
    Validity_Date: '到期日',
    The_country_for_which_it_operates: '其运营所在国家',
    Referral_Program: '【邀请有礼】',
    Invite_1_new_user: '邀1位新用户赚100元运费，多邀多得，不限量！',
    promo_code_will_become: '<img loading="lazy" src="/assets/img/dollar.png" alt="dollar banknote" width="16" height="16">您的优惠码将在被您邀请的朋友完成首次付款后激活<img loading="lazy" src="/assets/img/star_struck.png" alt="star struck" width="16" height="16">',
    Currency: "货币",

    verification_code: '驗證碼',
    verification_code_incorrect: '您的验证码错误',
    send_code: '获取验证码\n',
    Explore_more_news: '了解更多新闻',
    feedback_title: '告诉我们您想说的，好的或坏的。我们想知道这一切！',
    Submit_Feedback: '提交反馈',
    Feedback: '反馈',
    Thank_you_for_feedback: '感谢您的反馈！我们会尽快审核。',
    register_message: '为确保顺利装运，我们的专员将尽快与您联系，收集您的身份证或营业执照照片，以备出口文件之用。',
    Proceed_to_Meest: '快速登录',
    parcel_is_being_processed: '包裹正在处理中，请1 分钟后再试。',
    All_countries: '🌏 所有国家*',
    EU: '<img loading="lazy" src="/assets/img/EU_flag.png" alt="europe flag" width="12" height="12"> 欧盟国家*',
    All_non_EU: '🌏 所有非欧盟国家*',
    All_countries_hint: '*促销码能在欧洲国家和其他地区使用，但在某些国家会被限制使用。',
    New_website: '返回官网',
    Date_of_birth: '出生日期',
    date_of_birth_notification: '更新您的个人资料，即可获得专属优惠！ 添加您的生日，享受特别礼遇。',
    How_do_I_use_the_coupon_code: '如何使用优惠码？',
    Using_Your_Promo_Code: `<i class="fa fa-check-square col-add"></i> <span class='text-bold'>如何使用:</span>
        <br/>使用优惠码，请首先从账户的“促销代码”复制您需要的代码，<i class="fa fa-chevron-circle-right col-figma-text-primary"></i> 然后在支付时将优惠码粘贴到优惠码填写区域
        <br/><i class="fa fa-check-square col-add"></i> <span class='text-bold'>优惠码的适用范围</span>
        <br/><i class="fa fa-exclamation-triangle col-warning"></i> 优惠码只适用于其创建日期之后创建的包裹，请确保该包裹是在优惠码发布后创建，以符合适用范围`,
    Package_Created_Successfully: '创建的软件包！',
    Package_Created_Successfully_Description: '您的包裹已经创建成功，准备好进行下一步。看起来一切都很棒！',
    Got_it: '明白了',
    Package_Moved_Successfully: '移动了包装！',
    Package_Moved_Successfully_Description: '您的包裹已成功移动到下一阶段。所有更新已应用。',
    Payment_Successful: '支付成功！',
    Payment_Successful_Description: '您的支付已成功处理',
    Loading: '加载中',
    WeChatAuthFailedTitle: '❎ 很抱歉，身份验证失败。',
    WeChatAuthFailedMessage: ' ✅ 请使用用户名和密码登录您的账户。\n' +
        '✅ 在个人资料中添加 WeChat，以便下次通过 WeChat 登录。',
    WeChatRegistrationFailedMessage: `此微信账号已在使用中。登录您的账户 <a href="${document.location.protocol}//${document.location.host}/auth/login" class='ms-1'>Cabinet</a>`,
    LoginFailedTitle: '登录失败',
    LoginFailedMessage: '用户名或密码不正确。请检查您的凭据并重试。',
    ProfileUpdateSuccessTitle: '个人资料更新成功！',
    ProfileUpdateSuccessMessage: '您的个人资料信息已成功更新。',
    ProfileUpdateErrorTitle: '个人资料更新失败',
    ProfileUpdateErrorMessage: '更新您的个人资料时出错。请重试。',
}

export default dictionary;

import React, { useEffect, useState, memo } from 'react';
import DatePicker                           from "react-datepicker";
import moment                               from "moment";

import "react-datepicker/dist/react-datepicker.css";

const FIRST_DATE = 'firstDate';
const SECOND_DATE = 'secondDate';

const MyDatePicker = (props) => {

    const {
        id,
        className,
        classNameFirstDate,
        classNameSecondDate,
        textFirst,
        textSecond,
        start,
        startEnd,
        formatProp,
        showTimeSelect = false,
        timeFormat=null,
        handle,
        styles,
        stylesFirstDate,
        stylesSecondDate,
        colFirst,
        colSecond,
        TitleCClassNames,
        TitleClassNames,
        DPCClassNames,
        firstDate,
        secondDate,
        setStartDatesToParent = true,
    } = props

    const storageDateJson = localStorage.getItem('date-filter');
    const storageDate = storageDateJson ? JSON.parse(storageDateJson) : null;

    const format = 'yyyy.MM.dd' || formatProp;
    const date = moment(new Date(start ?? new Date()));
    const month = date.month();
    const [dateObject, setDateObject] = useState(getDateObj(firstDate, secondDate));

    function getFirstDate(firstDate) {
        return firstDate ? moment(firstDate) : storageDate ? moment(new Date(storageDate.firstDate)) : moment(date.toDate())
    }
    function getSecondDate(secondDate) {
        return secondDate ? moment(secondDate) : storageDate ? moment(new Date(storageDate.secondDate)) : moment(date.month(month + 1).toDate())
    }

    function getDateObj(firstDate, secondDate) {
        return {
            firstDate: getFirstDate(firstDate),
            secondDate: getSecondDate(secondDate),
        }
    }

    useEffect(() => {
        if (typeof handle === 'function' && setStartDatesToParent)
            startEnd ? handle(dateObject) : handle(dateObject.firstDate)
    }, [])

    useEffect(() => {
        setDateObject({
            firstDate: getFirstDate(firstDate),
            secondDate: getSecondDate(secondDate),
        });
    }, [firstDate, secondDate])

    function onChange(date: Date, key) {
        const newDateObject = {...dateObject}
        newDateObject[key] = moment(date)
        setDateObject(newDateObject)
        if (typeof handle === 'function')
            startEnd ? handle(newDateObject) : handle(newDateObject.firstDate)
    }

    localStorage.setItem('date-filter', JSON.stringify(dateObject))

    return (
        startEnd
            ?
            <div id={id ?? 'my-date-picker'} style={{...styles}} className={`${className ? className : ''}`}>
                <div style={{...stylesFirstDate}} className={`row g-0 ${classNameFirstDate ? classNameFirstDate : ''}`}>
                    <div className={`${colFirst[0] ? 'col-'+colFirst[0] : ''} d-flex align-items-center ${TitleCClassNames}`}>
                        <span className={TitleClassNames}>{textFirst}</span>
                    </div>
                    <div className={`${colFirst[1] ? 'col-'+colFirst[1] : ''} ${DPCClassNames}`}>
                        <DatePicker
                            selected={dateObject[FIRST_DATE].toDate()}
                            onChange={(date: Date) => onChange(date, FIRST_DATE)}
                            dateFormat={format}
                            showTimeSelect={showTimeSelect}
                            timeFormat={timeFormat}
                            maxDate={dateObject[SECOND_DATE].toDate()}
                        />
                    </div>
                </div>
                <div style={{...stylesSecondDate}}
                     className={`row g-0 ${classNameSecondDate ? classNameSecondDate : ''}`}>
                    <div className={`${colSecond[0] ? 'col-'+colSecond[0] : ''} d-flex align-items-center ${TitleCClassNames}`}>
                        <span className={TitleClassNames}>{textSecond}</span>
                    </div>
                    <div className={`${colSecond[1] ? 'col-'+colSecond[1] : ''} ${DPCClassNames}`}>
                        <DatePicker
                            selected={dateObject[SECOND_DATE].toDate()}
                            onChange={(date: Date) => onChange(date, SECOND_DATE)}
                            dateFormat={format}
                            showTimeSelect={showTimeSelect}
                            timeFormat={timeFormat}
                            minDate={dateObject[FIRST_DATE].toDate()}
                        />
                    </div>
                </div>
            </div>
            :
            <div id={id ?? 'my-date-picker'} className={`${className ? className : ''}`}>
                <div style={{...stylesFirstDate}} className={`row g-0 ${classNameFirstDate ? classNameFirstDate : ''}`}>
                    <div className={`col-${colFirst[0]} d-flex align-items-center`}>
                        <span>{textFirst}</span>
                    </div>
                    <div className={`col-${colFirst[1]}`}>
                        <DatePicker
                            selected={dateObject[FIRST_DATE].toDate()}
                            onChange={(date: Date) => onChange(date, FIRST_DATE)}
                            dateFormat={format}
                        />
                    </div>
                </div>
            </div>
    );
};

export default memo(MyDatePicker);

import React, { useState, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import SelectRS from 'react-select';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import ModalButtons from "../shared/modal/ModalButtons";
import InputText from "../input/InputText";
import CheckBox from "../input/CheckBox";
import {noEmptyValidate, validateLengthWithoutNumber} from "../../utils/validate";
import {saveAnswer} from "../../utils/api";
import {hideModalForm, checkValidate} from "../../utils/misc";
import Select from "../input/Select";
import {setPoll, setModalForm, SET_NEWS_STOP_CAROUSEL} from "../../storage/global";
import ProgressBar from "../shared/ProgressBar";

const PollForm = ({poll}) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const modalForm = useSelector(state => state.global.modalForm)

    const formRef = React.useRef();
    const FORM_ID = 'FORM-QUESTION'

    const lang = i18n.language || 'en'

    function buildAnswerForm(poll) {
        return {
            id_question: poll?.id,
            answers: poll?.questions ? poll.questions.map((que) => ({
                en: que.en,
                cn: que.cn,
                type: que.type,
                require: que.require,
                variants: que.variants,
                value: que.type === 1 ? [t('nothingSelected')] : '',
                minmax: que.minmax,
                starCount: que.starCount,
                answerData: {},
            })) : []
        }
    }

    const [step, setStep] = useState(0)
    const [pollAnswers, setPollAnswers] = useState(buildAnswerForm(poll))
    const [inSaving, setSaving] = useState(false)

    useEffect(() => {
        setPollAnswers(buildAnswerForm(poll))
    }, [poll])

    function buildContent(i, que) {
        const nothingSelected = t('nothingSelected');
        const validEmpty = t('validEmpty');
        const validation = [noEmptyValidate(validEmpty),
            validateLengthWithoutNumber(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
            // (value:string) => {return (/\s/ig.test(value)) || !value ? '' : t('Incorrect');}
        ];
        const validationVariantAdditionalData = [noEmptyValidate(validEmpty),
            validateLengthWithoutNumber(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        ];
        const variants = que.variants.map( (el, i) => ({label: el[lang], value: i, withInput: el.withInput}));
        const answerEl = pollAnswers.answers[i];
        const ansVal = answerEl.value;
        const answerOrAnswerList = typeof ansVal === 'string' ? ansVal?.trim() : ansVal;
        const selectedVariants = variants.filter(option => typeof ansVal === 'string' ?
            option.label === answerOrAnswerList
            : answerOrAnswerList.find( (answer) => answer === option.label)
        );
        const validError = que.type === 0 ? checkValidate(answerOrAnswerList, validation) : {isError: false, message: ''};
        const errorEmpty = answerOrAnswerList === nothingSelected
            || (que.type === 1 && (Array.isArray(answerOrAnswerList) && (!answerOrAnswerList.length || answerOrAnswerList[0] === nothingSelected)))
            || answerOrAnswerList === validEmpty
            || (que.type === 3 && !answerOrAnswerList)
            || (typeof answer === 'string' && !answerOrAnswerList.length);
        const errorMessage = que.require && errorEmpty && !(que.type === 3 && que.starCount === 1) ?
            validEmpty
            : que.require && validError.message ? validError.message : '';
        const {min, max} = que.minmax || {};
        const countStars =  +max + 1 - min;
        const middleVal =  (+max + min)/2;

        return <div key={i}>
            <div
                style={{
                    width: '100%',
                    marginTop: '-10px',
                }}
            >
                <ProgressBar completed={(((step-1)/poll.questions.length)*100).toFixed(2)}/>
                <div className="mx-auto wfc">
                    {step + ' / ' + poll.questions.length}
                </div>
            </div>
            <div className={'p-2 pt-3'}>
                <p
                    className={'poll-question'}
                    style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        overflow: 'hidden',
                    }}
                >
                    {que[lang]}
                </p>
                {que.type === 0 ?
                    <InputText
                        id={'q-text'}
                        handleChange={ val => saveChange(i, val)}
                        value={ansVal}
                        closeButton
                        errorMessage={errorMessage}
                        validations={validation}
                        placeholder={validEmpty}
                        classes={"rounded-0"}
                        styles={{
                            borderTopWidth: '0px',
                            borderRightWidth: '0px',
                            borderLeftWidth: '0px',
                            borderBottomWidth: '2px',
                        }}
                    />
                    : que.type === 1 ? <>
                        <SelectRS
                            key={'q-select'}
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            menuIsOpen
                            onChange={(newValue) => {
                                let newArr = [...answerOrAnswerList];
                                let newList = [];
                                if(que.multiple_choice) {
                                    if(typeof answerOrAnswerList === 'string') {
                                        newList = [newValue.label]
                                    } else if(answerOrAnswerList.find(a => a === newValue.label)) {
                                        newArr.splice(newArr.indexOf(newValue.label), 1)
                                        newList = newArr;
                                    } else if(answerOrAnswerList.length === 1 && answerOrAnswerList[0] === "Nothing selected") {
                                        newList = [newValue.label];
                                    } else {
                                        newList = [...answerOrAnswerList, newValue.label];
                                    }
                                } else {
                                    newList = [newValue.label]
                                }
                                saveChange(i, newList)
                            }}
                            value = {selectedVariants}
                            styles={selectStyles}
                            options={variants}
                            // getOptionValue ={(option)=>option.value}
                            formatOptionLabel={formatOptionLabel(que.multiple_choice, answerOrAnswerList)}
                        />
                        {!!selectedVariants.length && !!selectedVariants.find( (selectedEl) => selectedEl.withInput) && selectedVariants.map((el, indexFromSelected) => {
                            const AdditionalInputValue = answerEl.answerData.variantsAdditionalInputs ? answerEl.answerData.variantsAdditionalInputs[el.value] : '';
                            const AdditionalInputErrorMessage = answerEl.answerData.variantsAdditionalInputsErrors ? answerEl.answerData.variantsAdditionalInputsErrors[el.value] : '';
                            return <div style={{ width: '80%' }} className="d-dlex row mx-auto">
                                {el.withInput && <>
                                    <div className="col-12"><b>{t('Annex_to_the_item')} {el.label}</b></div>
                                    <InputText
                                        id={'q-text'}
                                        handleChange={ val => {
                                            const errorMessage = checkValidate(val, validationVariantAdditionalData).message || '';
                                            saveAnswerData(i,
                                                {...(answerEl.variantsAdditionalInputs || {}), [el.value]: val},
                                                {...(answerEl.variantsAdditionalInputsErrors || {}), [el.value]: errorMessage},
                                            )
                                        }}
                                        value={AdditionalInputValue}
                                        closeButton
                                        errorMessage={AdditionalInputErrorMessage}
                                        validations={validationVariantAdditionalData}
                                        placeholder={t('Fill_in_the_field')}
                                        groupClssses={'px-0 col-12'}
                                        classes={"rounded-0"}
                                        styles={{
                                            borderTopWidth: '0px',
                                            borderRightWidth: '0px',
                                            borderLeftWidth: '0px',
                                            borderBottomWidth: '2px',
                                        }}
                                    />
                                </>}
                            </div>
                        })}
                    </>
                    : que.type === 2 ? <div className="mx-auto"
                        style={{
                            width: (countStars+1)*50 + 45*(countStars-1) + 'px',
                            maxWidth: '100%',
                        }}
                    >
                        <RangeSlider
                            min={min}
                            max={max}
                            className="poll-range"
                            defaultValue={[0, (middleVal).toFixed(0)]}
                            value={[min, answerOrAnswerList]}
                            onInput={(values) => {
                                saveChange(i, values[1].toFixed(0));
                            }}
                            thumbsDisabled={[true, false]}
                            rangeSlideDisabled={true}
                        />
                        <div className="mt-3 row mx-0 justify-content-between px-3">
                            {[...Array(countStars)].map((nothin, estimate, arr) => {
                                return <React.Fragment key={'0-'+estimate}>
                                    <div key={'1-'+estimate} className="px-0"
                                        style={{
                                            width: 'min-content',
                                            height: 20,
                                            textAlign: 'center',
                                            fontSize: '15px',
                                        }}
                                    > {+min + estimate} </div>
                                </React.Fragment>
                            })}
                        </div>
                    </div>
                    : <div key={'A'+i} className="d-flex mx-auto wfc">
                        {[...Array((que.starCount))].map((nothin, estimate, arr) => {
                            const firstStyle = estimate === 0 ? {
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px',
                            } : {};
                            const lastStyle = estimate === (arr.length - 1) ? {
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px',
                            } : {};
                            return <React.Fragment key={'0-'+estimate}>
                                <div key={'1-'+estimate} className=""
                                    style={{
                                        width: 50,
                                        height: 50,
                                        fontSize: '40px',
                                    }}
                                >
                                    <div className="star mx-auto cursor-pointer"
                                        style={{
                                            width: 45,
                                        }}
                                        onClick={() => {
                                            saveChange(i, estimate + 1)
                                        }}
                                    >
                                        <span className={`fa fa-star ${typeof answerOrAnswerList !== 'number' ? 'col-figma-not-active-middle' : (answerOrAnswerList - 1) >= estimate ? 'checked' : 'col-not-active-item'}`}
                                            style={{}}
                                        ></span>
                                    </div>
                                </div>
                            </React.Fragment>}
                        )}
                    </div>
                }
            </div>
            <div className="d-mobile-none">
                <ModalButtons buttons={{
                    cancel: {
                        text: t('Back'),
                        variant: 'outline-primary',
                        type: 'button',
                        action: () => setStep(step - 1),
                        style: {
                            borderRadius: '0px !important'
                        },
                        className: 'rounded-0 col-figma-text-primary borcol-figma-text-primary borcol-figma-accent-hover'
                    },
                    save: {
                        text: t('Continue'),
                        className: 'rounded-0 borcol-figma-accent',
                        action: async () => {
                            let newAnswers = JSON.parse(JSON.stringify(pollAnswers));
                            if(que.type == 2 && answerOrAnswerList === '') {
                                const midV = middleVal.toFixed(0);
                                newAnswers.answers[i].value = midV;
                                saveChange(i, midV);
                            }
                            if(step === poll.questions.length) {
                                const saveRes = await handleSubmit(que.type == 2 && answerOrAnswerList === '' ? newAnswers : undefined);
                                setSaving(false)
                                if(!saveRes) {
                                    setStep(step + 1);
                                }
                            } else {
                                setStep(step + 1);
                            }
                        },
                        disabled: !!errorMessage || inSaving,
                        style: {
                            borderRadius: '0px !important'
                        }
                    },
                    ...(que.type === 2 ? {
                        skip: {
                            text: t('Skip'),
                            variant: 'light',
                            className: 'rounded-0',
                            action: async () => {
                                if(step === poll.questions.length) {
                                    const saveRes = await handleSubmit();
                                    setSaving(false)
                                    if(!saveRes) {
                                        setStep(step + 1);
                                    }
                                } else {
                                    setStep(step + 1);
                                }
                            },
                            disabled: !!errorMessage || inSaving,
                            style: {
                                borderRadius: '0px !important'
                            }
                        }
                    } : {}),
                }}/>
            </div>
            <div className="d-mobile">
                <ModalButtons buttons={{
                    save: {
                        text: t('Continue'),
                        className: 'rounded-0 borcol-figma-accent',
                        action: async () => {
                            let newAnswers = JSON.parse(JSON.stringify(pollAnswers));
                            if(que.type == 2 && answerOrAnswerList === '') {
                                const midV = middleVal.toFixed(0);
                                newAnswers.answers[i].value = midV;
                                saveChange(i, midV);
                            }
                            if(step === poll.questions.length) {
                                const saveRes = await handleSubmit(que.type == 2 && answerOrAnswerList === '' ? newAnswers : undefined);
                                setSaving(false)
                                if(!saveRes) {
                                    setStep(step + 1);
                                }
                            } else {
                                setStep(step + 1);
                            }
                        },
                        disabled: !!errorMessage || inSaving,
                        style: {
                            borderRadius: '0px !important'
                        }
                    },
                    cancel: {
                        text: t('Back'),
                        variant: 'outline-primary',
                        type: 'button',
                        action: () => setStep(step - 1),
                        style: {
                            borderRadius: '0px !important'
                        },
                        className: 'rounded-0 col-figma-text-primary borcol-figma-text-primary borcol-figma-accent-hover'
                    },
                    ...(que.type === 2 ? {
                        skip: {
                            text: t('Skip'),
                            variant: 'light',
                            className: 'rounded-0',
                            action: async () => {
                                if(step === poll.questions.length) {
                                    const saveRes = await handleSubmit();
                                    setSaving(false)
                                    if(!saveRes) {
                                        setStep(step + 1);
                                    }
                                } else {
                                    setStep(step + 1);
                                }
                            },
                            disabled: !!errorMessage || inSaving,
                            style: {
                                borderRadius: '0px !important'
                            }
                        }
                    } : {}),
                }}/>
            </div>
        </div>
    }

    const formatOptionLabel = (multiple_choice, answerList) => ({ value, label, withInput }) => {
        return (<div style={{ display: "flex" }}>
            {multiple_choice &&
                <CheckBox
                    className={'select-var-item-checkbox mr-3'}
                    styles={{fontSize: '20px'}}
                    value={answerList.find(el => el === label)}
                />
            }
            <div>{label}</div>
        </div>);
    };

    const imgSrc = useMemo(() => {
        return poll.image?.image_data ?
        `data:${poll.image?.image_type};base64, ` + poll.image?.image_data
        : '/assets/img/poll-bg-def.png'
    }, [poll])

    const stepContent = useMemo(() => {
        if(!poll.questions) return null;
        if(step === 0) {
            return (<div>
                <div className="text-center poll-start-end px-4">
                    {i18n.language === 'en' ? poll.start_screen_en : poll.start_screen_cn}
                </div>
                <ModalButtons
                    className={'d-flex justify-content-center mt-3'}
                    buttons={{
                        save: {
                            text: t('Start'),
                            className: 'rounded-0 m-0 bgcol-figma-text-primary',
                            action: () => setStep(step + 1),
                            style: {
                                borderRadius: '0px !important'
                            }
                        }
                    }}
                />
            </div>)
        } else if(step === poll.questions?.length + 1) {
            return (<div>
                <div className="text-center poll-start-end px-4">
                    {i18n.language === 'en' ? poll.end_screen_en : poll.end_screen_cn}
                </div>
                <ModalButtons
                    className={'d-flex justify-content-center mt-3'}
                    buttons={{
                        save: {
                            text: t('Close'),
                            variant: 'primary',
                            disabled: notValid(),
                            className: 'rounded-0 bgcol-figma-accent bordercol-figma-accent',
                            action: hide,
                        }
                    }}
                />
            </div>)
        } else {
            return buildContent(step - 1, poll.questions[step - 1])
        }
    }, [poll, step, pollAnswers, inSaving])

    async function handleSubmit(pollAns = pollAnswers) {
        if (!notValid()) {
            if(inSaving) return;
            setSaving(true)
            const requestData = {
                data: {
                    id_question: pollAns.id_question,
                    answers: pollAns.answers.map((queAndAnswer, indexAnswer) => {
                        const variants = queAndAnswer.variants.map( (el, i) => ({label: el[lang], value: i, withInput: el.withInput}));
                        const value = queAndAnswer.value;
                        return {
                            en: queAndAnswer.en,
                            cn: queAndAnswer.cn,
                            value: (queAndAnswer.type == 2 && value !== '') ? +value + ''
                                : queAndAnswer.type == 1 ?
                                    value.map(val => {
                                        const labelIndex = variants.find(el => el.label === val)?.value;
                                        return !labelIndex || !queAndAnswer.answerData?.variantsAdditionalInputs ? val : val + ' ' + queAndAnswer.answerData.variantsAdditionalInputs[labelIndex];
                                    })
                                    : value
                        }
                    })
                }
            }

            return saveAnswer(requestData.data)
                .then(() => {
                    return;
                })
                .catch((err) => {
                    console.log("🚀  handleSubmit  err:", err);
                    console.log('saveAnswer: ', err.response);
                    return err
                })
        }
    }

    function notValid(): boolean {
        const isErr = !!pollAnswers.answers.find((el) => {
            const errorValue = el.type === 0 ? (!el.value.length || el.value === t('validEmpty')) : el.value === t('nothingSelected')
            return el.require && errorValue && !(el.type === 3 && el.starCount === 1)
        })
        return isErr
    }

    function saveChange(i, val) {
        const newAnswers = JSON.parse(JSON.stringify(pollAnswers));
        newAnswers.answers[i].value = val;
        setPollAnswers(newAnswers)
    }

    function saveAnswerData(i, vals, errorMessages) {
        const newAnswers = JSON.parse(JSON.stringify(pollAnswers));
        newAnswers.answers[i].answerData = {
            ...(newAnswers.answers[i].answerData || {}),
            variantsAdditionalInputs: vals,
            variantsAdditionalInputsErrors: errorMessages,
        };
        setPollAnswers(newAnswers)
    }

    function hide(e) {
        dispatch({type: SET_NEWS_STOP_CAROUSEL, payload: false})
        dispatch(setPoll({}));
        hideModalForm(dispatch)();
    }

    return (
        <div className={'overflow-auto p-relative poll-form'}>
            {!poll.require && <img
                onClick={hide}
                style={{
                    position: 'absolute',
                    top: 9,
                    right: 9,
                    zIndex: 10
                }}
                className={'my-modal-close cursor-pointer'} src="/assets/img/close-white.svg"
                alt="close"/>}
            <div id={FORM_ID} width={'100%'}>
                {<img
                    style={{
                        width: '100%',
                        height: '25vw',
                        maxHeight: '200px',
                        objectFit: 'cover',
                    }}
                    className={''}
                    src={imgSrc}
                    alt=""/>}
                <div className="p-4 poll-form-content">
                    {stepContent}
                </div>
            </div>
            <ReactTooltip effect={'solid'} className={'my-tooltip'} delayHide={100}/>
        </div>
    );
};

const selectStyles = {
    container: (provided) => ({
        ...provided,
        zIndex: 99,
        backgroundColor: '#fff',
        width: '400px',
        maxWidth: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
    }),
    control: (provided) => ({
        ...provided,
        display: 'none'
    }),
    menu: () => ({
        // border: '1px solid #000',
        // boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'
    }),
    menuList: (menuL) => ({
        ...menuL,
        maxHeight: '500px'
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        fontSize: "18px",
        background: isSelected
            ? '#054ca8'
            : isFocused
                ? '#97c1f7'
                : '#ECEFF4',
        color: isSelected
            ? '#fff'
            : isFocused
                ? '#054ca8'
                : '#014bad',
        border: '2px solid',
        borderRadius: '0px',
        borderColor: isSelected
            ? '#014bad'
            : isFocused
                ? '#054ca8'
                : '#014bad',
        marginTop: '4px',
        marginBottom: '4px',
        fontWeight: 600,
      }),
};

export default PollForm;


import React from "react";

import AdminPanel     from "../pages/admin/AdminPanel";
import { routesNames } from "../constants";
import TabSystemSettings from "../pages/admin/TabSystemSettings";
import TabPollSettings from "../pages/admin/TabPollSettings";
import TabResponsibleEmails from "../pages/admin/TabResponsibleEmails";
import TabProfileSettings from "../pages/admin/TabProfileSettings";
import TabNotificationSettings from "../pages/admin/TabNotificationSettings";
import TabListLogs from "../pages/admin/TabListLogs";
import TabRequestFeedback from "../pages/admin/TabRequestFeedback";
import TabEmailReports from "../pages/admin/TabEmailReports";

import adminABTests            from "./adminABTests";
import adminRequests        from "./adminRequests";

const admin = [
    {
        path: '/' + routesNames.ADMIN,
        element: <AdminPanel/>,
        children: [
            {
                index: true,
                path: '/' + routesNames.SYSTEM_SETTINGS,
                element: <TabSystemSettings/>
            },
            {
                path: '/' + routesNames.POLL_SETTINGS,
                element: <TabPollSettings/>
            },
            {
                path: '/' + routesNames.RESPOSIBLE_EMAILS,
                element: <TabResponsibleEmails/>
            },
            {
                path: '/' + routesNames.PROFILE_SETTINGS,
                element: <TabProfileSettings/>
            },
            {
                path: '/' + routesNames.NOTIFICATION_SETTINGS,
                element: <TabNotificationSettings/>
            },
            {
                path: '/' + routesNames.LIST_LOGS,
                element: <TabListLogs/>
            },
            ...adminRequests,
            {
                path: '/' + routesNames.FEEDBACK,
                element: <TabRequestFeedback/>
            },
            ...adminABTests,
            {
                path: '/' + routesNames.EMAIL_REPORTS,
                element: <TabEmailReports/>
            },
        ],
    }
]

export default admin;
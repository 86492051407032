import React, { useMemo }                   from "react";
import { useDispatch, useSelector }         from "react-redux";
import { useNavigate }                      from "react-router-dom";
import { useTranslation }                   from "react-i18next";
import moment                               from "moment";

import localStyles from "./styles.module.scss";
import { setListPropertiesSelectFromOut, setModalForm } from "../../../../storage/global";
import PollForm from "../../../../components/forms/PollForm";
import ProfileForm from "../../../../components/forms/ProfileForm";
import { routesNames } from "../../../../constants";

const NotificationList: React.FC = ({close}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    const poll = useSelector(state => state.global.poll);
    const profileInfo = useSelector(state => state.global.profile);
    const balanceNotPaid = useSelector(state => state.global.balanceNotPaid);
    const lostList = useSelector(state => state.global.lost.list)
    const promoCodesList = useSelector(state => state.global.promoCodesList);

    const noPaidNotification = useMemo(() => {
        if (Number(balanceNotPaid.debt) > 0) return t('noPaidNotification')
        .replace(/(\$number)/, balanceNotPaid.count)
        .replace(/(\$amount)/, balanceNotPaid.debt)
        .replace(/(\$currency)/, profileInfo.currency);
        return '';
    }, [balanceNotPaid, profileInfo]);

    const pollDate = useMemo(() => {
        if (poll.id) {
            const d = new Date(+poll.update);
            const m = moment(d);
            const text = m.format('YYYY.MM.DD HH:mm');
            return text;
        }
        return '';
    }, [poll]);

    const promoCodes = useMemo(() => {
        const promoCodeNotifications = [];
        return promoCodesList.filter((el) => {
            const existBefore = promoCodeNotifications.includes(el.messageEn);
            if(el.messageEn === 'You have been assigned a personal referral promo code. Take advantage of the opportunity to earn bonuses for each new user you invite!') {
                promoCodeNotifications.push(el.messageEn);
            }
            return !el.messageEn || !existBefore;
        });
    }, [promoCodesList]);

    function openSentParsels () {
        dispatch(setListPropertiesSelectFromOut(balanceNotPaid.parcels));
        navigate(`/${routesNames.SENT_PARCELS}`);
        close();
    }

    function openLostParsels (TrackNumber) {
        navigate(`/${routesNames.UNIDENTIFIED_PARCELS}`);
        // dispatch(setSelectFromOut(TrackNumber));
        close();
    }

    function openPromoCodes () {
        navigate(`/${routesNames.PROMO_CODES}`);
        close();
    }

    function openPoll () {
        dispatch(setModalForm({
            title: '',
            form: <PollForm poll={poll}/>,
            fullScreen: true,
            formContent: {
                classNames: 'rounded-0'
            },
            formBody: {
                classNames: 'p-0 modal-form-body-poll'
            },
            hideOut: false,
            styles: {
                zIndex: 2,
                background: 'rgba(0, 0, 0, 0.5)',
            },
            // withoutClose: poll.require
            withoutClose: true
        }))
    }

    function openProfile () {
        close();
        dispatch(setModalForm({
            title: t('profile'),
            form: <ProfileForm/>,
            formContent: {classNames: 'modal-form-content-profile'},
        }))
    }

    const Notification = t('Notification');
    const SurveyDated = t('survey_dated');

    return (
        <div className={localStyles.container}>
            <div className={`${localStyles.popupTitleContainer}`}>
                <p className={`${localStyles.popupTitle} btn-outline-primary-no-border`}>{Notification}</p>
            </div>
            {noPaidNotification ?
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={() => openSentParsels()}>
                    <p dangerouslySetInnerHTML={{ __html: noPaidNotification }}></p>
                </div>
                : null
            }
            {lostList.length ?
                // lostList.map( (el, i) => {
                //     return <div key={i} className={`${localStyles.notifsListItem} cursor-pointer`} onClick={() => openLostParsels(el.TrackNumber)}>
                //         <p>{t('lostParcelNotification').replace(/(\$trackNumbers)/, el.TrackNumber)}</p>
                //     </div>
                // })
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={() => openLostParsels()}>
                    <p dangerouslySetInnerHTML={{ __html: t('lostParcelsNotification') }}></p>
                </div>
                : null
            }
            {/* should work with a closed active promo code, DOES NOT WORK */}
            {pollDate ?
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={openPoll}>
                    <p>{i18n.language === 'en' ? `${SurveyDated} ${pollDate}` : `${pollDate} ${SurveyDated}`}</p>
                </div>
                : null
            }
            {!profileInfo?.birthday ?
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={openProfile}>
                    <p>{t('date_of_birth_notification')}</p>
                </div>
                : null
            }
            {promoCodes.map((promoCode, index) => {
                const nowTime = new Date();
                const finishDate = new Date(promoCode.finishDate);
                const diffTime = Math.abs(finishDate - nowTime);
                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                const mFinishDate = moment(finishDate);
                const textFinishDate = mFinishDate.format('YYYY.MM.DD');
                let notifItem = '';

                const referralMessageEn = 'You have been assigned a personal referral promo code. Take advantage of the opportunity to earn bonuses for each new user you invite!';
                const referralMessageCn = '您已获得推荐促销代码。抓住机会，每邀请一位新用户即可获得奖励！';

                if (promoCode.messageEn === referralMessageEn ||
                    promoCode.messageEn?.includes('personal referral promo code')) {

                    notifItem = i18n.language === 'en'
                        ? '🤑You have been assigned a personal referral **promo code**💲. Take advantage of the opportunity to earn bonuses for each new user you invite!🙌'
                            .replace(/\*\*promo code\*\*/, `<b>promo code ${promoCode.promocodeNum}</b>`)
                        : '🤑您已获得推荐促销代码💲。抓住机会，每邀请一位新用户即可获得奖励！🙌'
                            .replace(/促销代码/, `促销代码 ${promoCode.promocodeNum}`);
                }
                else if(!diffDays && (promoCode.expireMessageCn || promoCode.expireMessageEn)) {
                    notifItem = i18n.language === 'en'
                        ? promoCode.expireMessageEn.replace('{promo code}', promoCode.promocodeNum).replace('{促销代码}', promoCode.promocodeNum)
                        : promoCode.expireMessageCn.replace('{promo code}', promoCode.promocodeNum).replace('{促销代码}', promoCode.promocodeNum)
                } else if(promoCode.messageCn || promoCode.messageEn) {
                    notifItem = i18n.language === 'en'
                        ? promoCode.messageEn
                            .replace('{promo code}', promoCode.promocodeNum)
                            .replace('{促销代码}', promoCode.promocodeNum)
                            .replace('{expiration date}', textFinishDate)
                            .replace('{过期日期}', textFinishDate)
                            .replace('{discount}', promoCode.value)
                            .replace('{折扣}', promoCode.value)
                        : promoCode.messageCn
                            .replace('{promo code}', promoCode.promocodeNum)
                            .replace('{促销代码}', promoCode.promocodeNum)
                            .replace('{expiration date}', textFinishDate)
                            .replace('{过期日期}', textFinishDate)
                            .replace('{discount}', promoCode.value)
                            .replace('{折扣}', promoCode.value)
                }

                const transformedString = notifItem.replace(/\\U000([0-9A-F]{4,5})/g, (_, hex) => {
                    return String.fromCodePoint(`0x${hex}`);
                });

                return notifItem ? <div key={index} className={`${localStyles.notifsListItem} cursor-pointer`} onClick={openPromoCodes}>
                    <p dangerouslySetInnerHTML={{ __html: transformedString }}></p>
                </div> : null;
            })}
        </div>

    )
}

export default NotificationList;



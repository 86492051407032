
import React from "react";

import { routesNames }          from "../constants";
import AdminABTestsPanel        from "../pages/admin/AdminABTestsPanel";
import TabABTest                from "../pages/admin/TabABTest";
import TabABRegistrationSuccess from "../pages/admin/TabABRegistrationSuccess";
import TabABHsCommod            from "../pages/admin/TabABHsCommod";

const adminABTests = [
    {
        path: '/' + routesNames.AB_TEST,
        element: <AdminABTestsPanel/>,
        children: [
            {
                path: '/' + routesNames.AB_TEST_SETTINGS,
                element: <TabABTest/>
            },
            {
                index: true,
                path: '/' + routesNames.AB_TEST_LIST,
                element: <TabABRegistrationSuccess/>
            },
            {
                index: true,
                path: '/' + routesNames.AB_TEST_HS_COMMOD,
                element: <TabABHsCommod/>
            },
        ],
    }
]
export default adminABTests;
import React from 'react';
import { useSelector } from "react-redux";
import Lottie from 'lottie-react';
import animationData from '../_styles/files/default_loader_all_year.json';

export default function Loader(props) {
    const load = useSelector(state => state.global.load)

    return (
        load ?
            <div id='main-loader' style={{
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'white',
                width: '100%',
                height: '100%',
                zIndex: 999
            }}
                 className={'d-flex justify-content-center align-items-center'}
            >
                <div className="flex justify-center items-center overflow-hidden rounded-full" style={{
                    width: '700px',
                    height: '700px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    borderRadius: '50%'
                }}>
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                    />
                </div>
            </div>
            : null
    );
}

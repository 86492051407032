/* eslint-disable react-hooks/exhaustive-deps */

import React    from 'react';
import { useTranslation }               from "react-i18next";
import { useDispatch }                  from "react-redux";

import ModalButtons                         from "./modal/ModalButtons";
import { hideModalForm }                    from "../../utils/misc";

const ModalQuestionWeChatLogin = ({weChat_url, text}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    function hideModal() {
        hideModalForm(dispatch)()
    }

    function generQR() {
        if(!weChat_url) return console.log('empty weChatUrl');
        if(weChat_url) window.location.href = weChat_url;
    }

    return (
        <div className='m-4'>
            <div className="mx-auto text-center">
                {text}
            </div>
            <ModalButtons buttons={{
                cancel: {
                    text: t('no'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideModal,
                },
                save: {
                    text: t('yes'),
                    variant: 'primary',
                    type: 'submit',
                    action: generQR
                }
            }}/>
        </div>
    );
};

export default ModalQuestionWeChatLogin;
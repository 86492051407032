import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { useTranslation }                               from "react-i18next";
import { Button }                                       from "react-bootstrap";
import moment                                           from "moment/moment";
import qs                                               from 'query-string';

import {
    CREATED_REQUEST,
    NAME_METRICS,
    VALUE,
    PERCENTAGE,
    CAPTCHA_SWITCH,
    DEFAULT_ERROR_OBJECT,
    EMAIL,
    IC_CRD_NUM, INPUT_CAPTCHA, MANAGER,
    COMPANY_NAME, WECHAT_ID,
    NAME,
    PASSWORD,
    PASSWORD_CONFIRM,
    PASSWORD_AGAIN,
    DOCUMENTS_IMAGES,
    PHONE,
    routesNames,
    PHONE_VERIFY,
    action_create_parcel_form_success,
}                       from "../../../constants";
import axios                                            from "../../../lib/axios";
import { setLoad, setLoadSpinner }                      from "../../../storage/global";
import { getRequestsUris, makeGetRequest, makePostRequest}  from "../../../utils/api";
import { viewAlert }                                    from "../../../utils/misc";
import MyDatePicker                                     from "../../../components/shared/datepicker/MyDatePicker";
import Select                                           from "../../../components/input/Select";
import AnalyticsByPercentages                           from "../../../components/shared/RequestSettings/AnalyticsByPercentages";

const TabUserMetricks = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const keysFields = {
        [COMPANY_NAME]: t('your_company'),
        [NAME]: t('your_name'),
        [PHONE]: t('phone_number'),
        [IC_CRD_NUM]: t(IC_CRD_NUM),
        [WECHAT_ID]: t('wechatID'),
        [EMAIL]: t(EMAIL),
        [PASSWORD]: t(PASSWORD),
        [PASSWORD_CONFIRM]: t(PASSWORD_AGAIN),
        [INPUT_CAPTCHA]: t('input_captcha'),
        [CAPTCHA_SWITCH]: 'I am not a robot',
        [MANAGER]: t(MANAGER),
        [DOCUMENTS_IMAGES]: t(DOCUMENTS_IMAGES),
    };

    const nothingSelected = t('nothingSelected');

    const [dateForRegistration, setForRegistration] = useState({ date_from: Date.now(), date_to: Date.now()});
    const [analyticsRegistration, setAnalyticsRegistration] = useState([]);
    const [analyticsCreateParcel, setAnalyticsCreateParcel] = useState([]);
    const [dateForDataByClient, setForDataByClient] = useState({ date_from: Date.now(), date_to: Date.now()});
    const [analyticsByClient, setAnalyticsByClient] = useState([]);
    const [averageTimeByClientId, setAverageTimeByClientId] = useState(0);
    const [dateForRegFormFieldsStat, setDateForRegFormFieldsStat] = useState({ date_from: Date.now(), date_to: Date.now()});
    const [analyticsRegFormFields, setAnalyticsRegFormFields] = useState([]);
    const [analyticsWithAB, setAnalyticsWithAB] = useState(false);

    useEffect(() => {
        if(analyticsByClient.length) {
            const analyticsSortByDate = analyticsByClient.sort((a,b) => {
                let date_a = new Date(a.created_request);
                let date_b = new Date(b.created_request);
                const timeReal_a = date_a.getTime();
                const timeReal_b = date_b.getTime();
                return timeReal_a - timeReal_b;
            })
            const dataByClientId = {};
            analyticsSortByDate.forEach( (el, i) => {
                if(dataByClientId[el.client_id]) {
                    dataByClientId[el.client_id].push(el.created_request);
                } else {
                    dataByClientId[el.client_id] = [el.created_request];
                }
            });
            const averageTimeByClientId_ = {};
            Object.keys(dataByClientId).forEach( (key, i) => {
                let averageTimeClient = 0;
                const clientSessionsTime = [];
                let requestStartTime = new Date(dataByClientId[key][0]).getTime();
                let requestLastTime = requestStartTime;
                dataByClientId[key].forEach( (request_time, i) => {
                    const request_time_num = new Date(request_time).getTime();
                    const diff_time = request_time_num - requestLastTime;
                    if(diff_time > 20*60*1000) {
                        const sessionTime = requestLastTime - requestStartTime;
                        sessionTime > 0 && clientSessionsTime.push(sessionTime);
                        requestStartTime = request_time_num;
                        requestLastTime = requestStartTime;
                    } else {
                        requestLastTime = request_time_num;
                    }
                });
                averageTimeClient = 0;
                if(clientSessionsTime.length) {
                    const sum = clientSessionsTime.reduce(function(sum, current) {
                        return sum + current;
                    }, 0);
                    averageTimeClient = Math.floor(sum/clientSessionsTime.length);
                }
                averageTimeByClientId_[key] = averageTimeClient;
            });
            const sumTime = Object.keys(averageTimeByClientId_).reduce( function (sum, cl_id) {
                return sum + averageTimeByClientId_[cl_id];
            }, 0);
            const averageTime = Math.floor(sumTime/Object.keys(averageTimeByClientId_).length);
            const sec = Math.floor(averageTime/1000)
            const min = Math.floor(sec/60)
            const hour = Math.floor(min/60)
            const rest_milisec = averageTime - sec*1000
            const rest_sec = sec - min*60
            const rest_min = min - hour*60
            const session_active_time = hour + ' hours, ' + rest_min + ' minutes, ' + rest_sec + ' seconds';
            setAverageTimeByClientId(session_active_time);
        } else {
            setAverageTimeByClientId(0);
        }
    }, [analyticsByClient])

    function loadData() {
        dispatch(setLoadSpinner(true))
        makeGetRequest('/api/admin/getAnalitics', {
                date_from: dateForRegistration.date_from,
                date_to: dateForRegistration.date_to
            },
            {}
        ).then(({data}) => {
            const signup = data.uris.filter( (el) => el.req_uri === '/api/auth/signup');
            const analytics = data.uris.filter( (el) => el.req_uri !== '/api/auth/signup');
            const r_f_opened = [];
            const r_f_started_filling_uped = [];
            const c_p_f_opened = [];
            const c_p_f_started_filling_uped = [];
            const c_p_f_successed = [];
            analytics.forEach( ({ req_uri, uri_full }) => {
                const indexOfParams = uri_full.indexOf('?');
                const strParams = uri_full.substring(indexOfParams);
                const {action, a_data} = qs.parse(strParams);
                if(action === 'registration_form_open') r_f_opened.push({action, a_data})
                else if(action === 'registration_form_started_filling_up') r_f_started_filling_uped.push({action, a_data})
                else if(action === 'create_parcel_form_open') c_p_f_opened.push({action, a_data})
                else if(action === 'create_parcel_form_started_filling_up') c_p_f_started_filling_uped.push({action, a_data})
                else if(action === action_create_parcel_form_success) c_p_f_successed.push({action, a_data})
            });
            const itemsRegistration = [
                {
                    [NAME_METRICS]: 'Open registration form',
                    [VALUE]: r_f_opened.length,
                    [PERCENTAGE]: '100%',
                },
                {
                    [NAME_METRICS]: 'Open registration form and fill one field',
                    [VALUE]: r_f_started_filling_uped.length,
                    [PERCENTAGE]: r_f_started_filling_uped.length ? ((r_f_started_filling_uped.length/r_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
                {
                    [NAME_METRICS]: 'Success registration',
                    [VALUE]: signup.length,
                    [PERCENTAGE]: signup.length ? ((signup.length/r_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
            ]
            const itemsCreateParcel = [
                {
                    [NAME_METRICS]: 'Open new parcel  form',
                    [VALUE]: c_p_f_opened.length,
                    [PERCENTAGE]: '100%',
                },
                {
                    [NAME_METRICS]: 'Open new parcel form and fill one field',
                    [VALUE]: c_p_f_started_filling_uped.length,
                    [PERCENTAGE]: c_p_f_started_filling_uped.length ? ((c_p_f_started_filling_uped.length/c_p_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
                {
                    [NAME_METRICS]: 'Success parcel creation',
                    [VALUE]: c_p_f_successed.length,
                    [PERCENTAGE]: c_p_f_successed.length ? ((c_p_f_successed.length/c_p_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
            ]
            setAnalyticsRegistration(itemsRegistration);
            setAnalyticsCreateParcel(itemsCreateParcel);
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function loadExcel() {
        dispatch(setLoadSpinner(true))
        makePostRequest('/api/admin/printFormsAnalytics', {
                analyticsRegistration: analyticsRegistration,
                analyticsCreateParcel: analyticsCreateParcel,
            },
            {responseType: 'blob'}
        ).then(({data}) => {
            const date = moment();
            const filename = `print_forms_analytics_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function loadDataByClient() {
        dispatch(setLoadSpinner(true))
        makeGetRequest('/api/admin/getAnaliticsByClientId', {
                date_from: dateForDataByClient.date_from,
                date_to: dateForDataByClient.date_to
            },
            {responseType: 'blob'}
        ).then(({data}) => {
            setAnalyticsByClient(data.uris);
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDatePicker(dateObject, setDate) {
        const date = {
            date_from: dateObject.firstDate.format('YYYY.MM.DD'),
            date_to: dateObject.secondDate.format('YYYY.MM.DD')
        }
        setDate(date)
    }

    function showRegFormFieldsStat() {
        dispatch(setLoadSpinner(true))
        makeGetRequest('/api/admin/getRegistrationFormFieldStatistics', {
                date_from: dateForRegFormFieldsStat.date_from,
                date_to: dateForRegFormFieldsStat.date_to
            },
            {}
        ).then(({data}) => {
            const analytics = data.uris;

            let phoneIsVerifyCount = 0;
            const errorsFields = [];

            analytics.forEach( ({ req_uri, uri_full }) => {
                const indexOfParams = uri_full.indexOf('?');
                const strParams = uri_full.substring(indexOfParams);
                const {action, a_data} = qs.parse(strParams);
                const data = JSON.parse(a_data);
                if(data.withABTest && !analyticsWithAB) setAnalyticsWithAB(true);
                if(data.phoneIsVerify) phoneIsVerifyCount++;
                data.errors.forEach( ({ field, isError, message }) => {
                    const fieldStat = errorsFields.find((row) => row[NAME_METRICS] === field);
                    if(!fieldStat) {
                        errorsFields.push({
                            [NAME_METRICS]: field,
                            [VALUE]: +!isError,
                        })
                    } else if(fieldStat && !isError) {
                        fieldStat[VALUE] = fieldStat[VALUE] + 1;
                    }
                });
            });

            errorsFields.forEach((row) => {
                row[PERCENTAGE] = ((row[VALUE]/analytics.length)*100).toFixed(2) + '%'
            })
            if(analytics.length) {
                errorsFields.push({
                    [NAME_METRICS]: 'phoneIsVerifyCount',
                    [VALUE]: phoneIsVerifyCount,
                    [PERCENTAGE]: ((phoneIsVerifyCount/analytics.length)*100).toFixed(2) + '%',
                })
            }
            const errorsFields_ = errorsFields.map((field) => {
                field[NAME_METRICS] = keysFields[field[NAME_METRICS]] || field[NAME_METRICS];
                return field;
            })
            setAnalyticsRegFormFields(errorsFields_);
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function loadExcelRegFormFieldsStat() {
        dispatch(setLoadSpinner(true))
        makePostRequest('/api/admin/printRegFormFieldsStat', {
                analyticsRegFormFields,
            },
            {responseType: 'blob'}
        ).then(({data}) => {
            const date = moment();
            const filename = `reg_fields_analytics_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xls');
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    return (
        <div className={'container-page height-sub-tabs py-3 overflow-auto'}>
            <div className="px-3 pt-10 mb-3">
                Статистика форм
            </div>
            <div className="row w-100 m-0">
                <MyDatePicker
                    handle={(dateObj) => handleDatePicker(dateObj, setForRegistration)}
                    startEnd
                    firstDate={new Date(dateForRegistration.date_from ?? Date.now()-1000*60*60)}
                    secondDate={new Date(dateForRegistration.date_to ?? Date.now())}
                    className={'d-flex col-6'}
                    stylesFirstDate={{marginRight: '15px'}}
                    classNameFirstDate={'mr-3'}
                    textFirst={"З дати"}
                    textSecond={"До дати"}
                    colFirst={['auto', 'auto']}
                    colSecond={['auto', 'auto']}
                    TitleCClassNames={"pl-15 "}
                    TitleClassNames={"lh-0"}
                    DPCClassNames={"mtb-9 minw-100"}
                />
                <div className="col-3 align-self-center">
                    <Button onClick={loadData} className={'hmc mr-1 w-100'}>Показати</Button>
                </div>
                {(analyticsRegistration.length || analyticsCreateParcel.length) ? <div className="col-3 align-self-center">
                    <Button onClick={loadExcel} className={'hmc w-100'}>Скачати</Button>
                </div> : null}
            </div>
            <div className="row mx-0">
                <div className="col-6">
                    <AnalyticsByPercentages items={analyticsRegistration}/>
                </div>
                <div className="col-6">
                    <AnalyticsByPercentages items={analyticsCreateParcel}/>
                </div>
            </div>
            <div className="row mx-0">
                <div className="col-6">
                    <div className="px-3 pt-10 mb-3">
                        Статистика полів форми реєстрації
                    </div>
                    <div className="row w-100 m-0 mb-2">
                        <MyDatePicker
                            handle={(dateObj) => handleDatePicker(dateObj, setDateForRegFormFieldsStat)}
                            startEnd
                            firstDate={new Date(dateForRegFormFieldsStat.date_from ?? Date.now()-1000*60*60)}
                            secondDate={new Date(dateForRegFormFieldsStat.date_to ?? Date.now())}
                            className={'d-flex col-12'}
                            stylesFirstDate={{marginRight: '15px'}}
                            classNameFirstDate={'mr-3'}
                            textFirst={"З дати"}
                            textSecond={"До дати"}
                            colFirst={['auto', 'auto']}
                            colSecond={['auto', 'auto']}
                            TitleCClassNames={"pl-15 "}
                            TitleClassNames={"lh-0"}
                            DPCClassNames={"mtb-9 minw-100"}
                        />
                        <div className="col-6">
                            <Button onClick={showRegFormFieldsStat} className={'hmc mr-1 w-100'}>Показати</Button>
                        </div>
                        {(analyticsRegFormFields.length) ? <div className="col-6 align-self-center">
                            <Button onClick={loadExcelRegFormFieldsStat} className={'hmc w-100'}>Скачати</Button>
                        </div> : null}
                    </div>
                    <div className="">
                        <div className="px-3 py-2">
                            AB тестування присутнє: {analyticsWithAB ? 'так' : 'ні'}
                        </div>
                    </div>
                    <AnalyticsByPercentages
                        items={analyticsRegFormFields}
                        styleListContainer={analyticsRegFormFields.length ? {
                            height: 350
                        } : {}}
                    />
                </div>
                <div className="col-6">
                    <div className="px-3 pt-10 mb-3">
                        Середній час сеансу
                    </div>
                    <div className="row w-100 m-0">
                        <MyDatePicker
                            handle={(dateObj) => handleDatePicker(dateObj, setForDataByClient)}
                            startEnd
                            firstDate={new Date(dateForDataByClient.date_from ?? Date.now()-1000*60*60)}
                            secondDate={new Date(dateForDataByClient.date_to ?? Date.now())}
                            className={'d-flex col-12'}
                            stylesFirstDate={{marginRight: '15px'}}
                            classNameFirstDate={'mr-3'}
                            textFirst={"З дати"}
                            textSecond={"До дати"}
                            colFirst={['auto', 'auto']}
                            colSecond={['auto', 'auto']}
                            TitleCClassNames={"pl-15 "}
                            TitleClassNames={"lh-0"}
                            DPCClassNames={"mtb-9 minw-100"}
                        />
                        <div className="col-6 align-self-center">
                            <Button onClick={loadDataByClient} className={'hmc mr-1 w-100'}>Показати</Button>
                        </div>
                    </div>
                    <div className="">
                        <div className="px-3 pt-10">
                            Середній час сеансу {averageTimeByClientId}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TabUserMetricks;

import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form }                     from "react-bootstrap";
import { useTranslation }           from "react-i18next";

import { setAlert, setLoadSpinner, setModalForm, setList }      from "../../storage/global";
import ModalButtons                                                     from "../shared/modal/ModalButtons";
import SuccessMoved                                                    from "../shared/PopUpsSuccess/SuccessMoved";
import InputText                                                        from "../input/InputText";
import Select                                                           from "../input/Select";
import {
    letterNumberHyphenValidate,
    noEmptyValidate,
    numberValidate,
    validateLength
} from "../../utils/validate";
import { sendToWarehouse }                                              from "../../utils/api";
import { checkValidate, hideModalForm }                   from "../../utils/misc";
import { DEFAULT_ERROR_OBJECT, TRACK, CNTS, WAREHOUSE_FULL }            from "../../constants";

const WarehouseForm = ({count}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list)
    const loadSpinner = useSelector(state => state.global.spinner)

    const selectedList = useMemo(() => {
        return list.filter(parcel => parcel.check)
    }, [list])

    const options = [
        {
            text: 'GUANGZHOU',
            value: 'GZH'
        },
    ]

    const selectOption = {
        text: 'GUANGZHOU',
        value: 'GZH'
    }

    const validations = {}
    validations[TRACK] = [noEmptyValidate(t('validEmpty')), letterNumberHyphenValidate(t('validValueChinaTrack')), validateLength('5')]
    validations[CNTS] = [noEmptyValidate(t('validEmpty')), numberValidate()]

    const [errorTrack, setErrorTrack] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCnts, setErrorCnts] = useState({...DEFAULT_ERROR_OBJECT});

    const [track, setTrack] = useState('');
    const [cnts, setCnts] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        const requestData = {
            parcels: selectedList,
        };
        requestData[TRACK] = [form[TRACK].value]
        requestData[CNTS] = form[CNTS].value
        requestData[WAREHOUSE_FULL] = form[WAREHOUSE_FULL].value

        const errors = [
            checkValidate(form[TRACK].value, validations[TRACK], setErrorTrack),
            checkValidate(requestData[CNTS], validations[CNTS], setErrorCnts),
        ]

        const isError = errors.find(value => value.isError);

        if (!isError && !loadSpinner) {

            dispatch(setLoadSpinner(true));
            sendToWarehouse(requestData)
                .then(response => {
                    const filterList = list.filter((el) => selectedList.find(selectEl => selectEl.uid !== el.uid));
                    dispatch(setList(filterList));
                    dispatch(setModalForm({
                        title: '',
                        form: <SuccessMoved/>,
                        fullScreen: true,
                        hideOut: false,
                        withoutClose: true,
                    }));
                })
                .catch((response) => {
                    dispatch(setAlert({
                        title: t('error'),
                        text: response.statusText,
                        type: 'danger'
                    }))
                })
                .finally(() => dispatch(setLoadSpinner(false)))
        }
    }

    function handleValues(value, name) {

        switch (name) {
            case TRACK:
                checkValidate(value, validations[name], setErrorTrack)
                setTrack(value);
                checkInTrackNumber(value);
                break;
            case CNTS:
                checkValidate(value, validations[name], setErrorCnts)
                setCnts(value)
                break;
            default:
                break;
        }
    }

    function checkInTrackNumber(value) {
        selectedList.forEach( parcel => {
            if(parcel.code.toLowerCase() === value.toLowerCase().trim()) {
                setErrorTrack({isError: true, message: t('DomesticTrackCantMatchTrackNumber')});
            }
        })
    }

    return (
        <Form noValidate onSubmit={handleSubmit} className={''}>
            <p style={{
                maxWidth: '500px'
            }}>{t('wh_text_1').split('$1')[0]} <strong>{count}</strong> {t('wh_text_1').split('$1')[1]}</p>

            <table className={'w-100'}>
                <tbody>
                <tr>
                    <td valign={'top'}><span>{t('wh')}</span></td>
                    <td>
                        <Select
                            id={WAREHOUSE_FULL}
                            className={'mb-3'}
                            options={options}
                            selectValue={selectOption.value}
                            keyName={'value'}
                            nameValue={'text'}
                        />
                    </td>
                </tr>
                <tr>
                    <td valign={'top'}><span>{t('Domestic_express_tracking')}</span></td>
                    <td>
                        <InputText
                            closeButton classes={''}
                            id={TRACK}
                            required
                            as={'textarea'} rows={3}
                            value={track}
                            validations={validations[TRACK]}
                            errorMessage={errorTrack.message}
                            errorStyles={{position: 'relative'}}
                            handleChange={handleValues}
                            handleBlur={handleValues}
                            styles={{height: 'unset'}}
                        />
                    </td>
                </tr>
                <tr>
                    <td valign={'top'} className={''} style={{
                        width: '25%'
                    }}>
                        <span>{t('cnts')}</span>
                    </td>
                    <td>
                        <InputText
                            closeButton
                            classes={''}
                            type={'text'}
                            id={CNTS}
                            required
                            value={cnts}
                            validations={validations[CNTS]}
                            errorMessage={errorCnts.message}
                            errorStyles={{position: 'relative'}}
                            handleChange={handleValues}
                            handleBlur={handleValues}
                        />
                    </td>
                </tr>
                </tbody>
            </table>

            <ModalButtons buttons={{
                cancel: {
                    text: t('cancel'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideModalForm(dispatch)
                },
                send: {
                    text: t('send'),
                    variant: 'primary',
                    type: 'submit',
                    disabled: loadSpinner,
                }
            }}/>
        </Form>
    );
};

export default WarehouseForm;

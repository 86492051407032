import React, { useState, useEffect, useMemo } from 'react';
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import InputEmail                                                           from "../input/InputEmail";
import InputPassword                                                        from "../input/InputPassword";
import LinkOnLoginPage                                                      from "../shared/LinkOnLoginPage";
import ModalQuestionWeChatLogin                                             from "../shared/ModalQuestionWeChatLogin";
import WeChatAuthFailedModal                                                     from "../shared/modal/WeChatAuthFailedModal";
import { DEFAULT_ERROR_OBJECT, EMAIL, PASSWORD, routesNames }               from "../../constants";
import { login, makePostRequest }                                           from "../../utils/api";
import { checkValidate, serializeFormToObject }                             from "../../utils/misc";
import { emailValidate, noEmptyValidate, passwordValidateAlphabet, passwordValidateLenght }                 from "../../utils/validate";
import { setAlert, setLoadSpinner, setModalForm }                           from "../../storage/global";

const LoginForm = () => {
    const location = useLocation();

    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassword, setErrorPassword] = useState({...DEFAULT_ERROR_OBJECT});

    const [wechatAlertIsOpen, setWechatAlertIsOpen] = useState(false);
    const [wechatRegisterAlertIsOpen, setWechatRegisterAlertIsOpen] = useState(false);

    const savedAction = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const actionParam = params.get('action');
        return actionParam;
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const alertType = params.get('alertType');
        if (alertType === 'loginByWeChatNotFound') {
            setWechatAlertIsOpen(true);
        } else if (alertType === 'userByWeChatAlreadyRegistered') {
            setWechatRegisterAlertIsOpen(true);
        }
    }, []);

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validations = {
        [EMAIL]: [noEmptyValidate(t('validEmpty')), emailValidate(t('validEmail'))],
        [PASSWORD]: [noEmptyValidate(t('validEmpty')), passwordValidateAlphabet(t('validPasswordAlphabet')), passwordValidateLenght(t('validPassword'))]
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        const data = serializeFormToObject(form);

        const errors = [
            checkValidate(data[EMAIL], validations[EMAIL], setErrorEmail),
            checkValidate(data[PASSWORD], validations[PASSWORD], setErrorPassword),
        ]
        const isError = errors.filter(er => er.isError).length;

        if (!isError) {
            dispatch(setLoadSpinner(true));

            login(data)
                .then(response => {

                    let redirectPath = `/${routesNames.NEW_PARCELS}`;
                    if (savedAction) {
                        redirectPath += `?action=${savedAction}`;
                        sessionStorage.removeItem('redirectAction');
                    }

                    navigate(redirectPath);
                })
                .catch(({response}) => {
                    dispatch(setAlert({
                        title: t('message'),
                        text: response?.statusText || response?.statusTextError || '',
                        type: 'danger'
                    }));
                })
                .finally(() => {
                    dispatch(setLoadSpinner(false));
                });
        }
    }

    async function openModalWeChatQuestion() {
        const appId = process.env.REACT_APP_WECHAT_APP_ID;
        if(!appId) return console.log('empty REACT_APP_WECHAT_APP_ID');
        dispatch(setModalForm({
            title: '',
            withoutClose: true,
            form: <div className='m-4'>
                <div className="mx-auto text-center">
                    {t('Loading')}
                </div>
            </div>,
        }));
        let text = t('Something_went_wrong');
        const params = {};
        const url = await makePostRequest('/api/auth/wechat/pending', params, {}).then(async (res) => {
            const uuid = res.data?.uuid;
    
            text = t('LogInWithWeChat');
    
            const redirectUri = encodeURIComponent(`${document.location.protocol}//${document.location.host}/wechat/callback`);
            const state = uuid;
    
            const weChatUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_login&state=${state}#wechat_redirect`;
            return weChatUrl;
        })
        .catch((err) => {
            console.log('wechat/pending catch: ', err)
        })
        dispatch(setModalForm({
            title: '',
            withoutClose: true,
            form: <ModalQuestionWeChatLogin weChat_url={url} text={text}/>,
        }));
    }

    function handleValues(value, name) {
        if (name === EMAIL) {
            checkValidate(value, validations[name], setErrorEmail)
        } else if (name === PASSWORD) {
            checkValidate(value, validations[name], setErrorPassword)
        }
    }

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <InputEmail
                id={EMAIL}
                type={EMAIL}
                classes={''}
                errorMessage={errorEmail.message}
                validations={validations[EMAIL]}
                handleChange={handleValues}
                handleBlur={handleValues}
                closeButton
            />
            <InputPassword
                id={PASSWORD}
                type={PASSWORD}
                classes={'mt-4'}
                errorMessage={errorPassword.message}
                validations={validations[PASSWORD]}
                handleChange={handleValues}
                handleBlur={handleValues}
                closeButton
            />

            <LinkOnLoginPage className={'mt-4'} text={t('forget_password')} path={`/${routesNames.AUTH}/${routesNames.RESTORE}`}/>

            <div className="row mb-3 mx-0">
                <Button type="submit" className={'col-12'} style={{height: '40px'}}>
                    {t('sign_in')}
                </Button>
                <Button variant="" className={'col-12 h-100 my-p-0 mt-3 col-bg-wechat'} onClick={openModalWeChatQuestion}>
                    <div className="mx-auto">
                        <img width='26px' height='22px' className={'wechat-white-m'} style={{}} src={"/assets/img/wechat-white.png"} alt="logo"/>
                        {t('Login_with_WeChat')}
                    </div>
                </Button>
            </div>

            <LinkOnLoginPage className={'mb-0'} text={t('register')} path={`/${routesNames.AUTH}/${routesNames.SIGNUP}`}/>
            <WeChatAuthFailedModal
                isOpen={wechatAlertIsOpen || wechatRegisterAlertIsOpen}
                onClose={() => wechatAlertIsOpen ? setWechatAlertIsOpen(false) : setWechatRegisterAlertIsOpen(false)}
                messageText={wechatAlertIsOpen ? '' : t('WeChatRegistrationFailedMessage')}
            />
        </Form>
    );
};

export default LoginForm;
